/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './HumanResources.css';
import React, {useEffect, useRef, useState} from 'react';
import HRHub from './HRHub/HRHub.js';
import { ApiRequest } from '../../../ApiManager.tsx';
import moment from 'moment';
import LoadingWrapper from '../../../components/LoadingWrapper.js';
import HumanResourcesUser from './HumanResourcesUser.js';
import './HumanResources.css';
import Cookies from 'universal-cookie';
import DropdownControl from '../../../components/DropdownControl.js';

export default function HumanResources() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    const [users, setUsers] = useState([]);
    const [terminals, setTerminals] = useState([]);
    const [timeData, setTimeData] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(undefined);
    const [totalWeeklyHoursWorked, setTotalWeeklyHoursWorked] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companies, setCompanies] = useState([]);
    const auth = new Cookies().get('auth');
    const isAdminNotShadowing = !auth.companyUserIdentifier && auth.adminIdentifier;

    useEffect(() => {
        if(!isAdminNotShadowing || !!selectedCompany) {
            loadData();
        }
    }, [selectedDate, selectedCompany])

    useEffect(() => {
        if(!isAdminNotShadowing || !!selectedCompany) {
            getTotalWeeklyHoursWorked();
        }
    }, [timeData, selectedCompany])

    useEffect(() => {
        setSelectedEmployee(null);
    }, [selectedCompany])

    useEffect(() => {
        if (isAdminNotShadowing) {
            loadCompanies();
        }
    }, [])

    function loadCompanies(){
        new ApiRequest('admin', 'getCompanies', setIsLoading, (response) => {
            setCompanies(response.companies);
        }).withNoAlertOnSuccess().send()
    }

    function loadData() {
        new ApiRequest('hr', 'getAll', setIsLoading, (response) => {
            response.users.forEach((user) => {
                user.timeOff = response.timeOff.filter(t => t.companyUserIdentifier === user.companyUserIdentifier);
                user.route = response.routes.find(r => r.companyUserIdentifier === user.companyUserIdentifier);
                user.absences = response.absences.filter(r => r.companyUserIdentifier === user.companyUserIdentifier);
            });

            setUsers(response.users.sort(sortUsers));
            setTerminals(response.terminals);
            setTimeData(response.timesheetEntries);
            setTotalWeeklyHoursWorked(parseFloat(response.hoursWorkedInWeek));
        }).withData({date: selectedDate, companyIdentifier: selectedCompany}).withNoAlertOnSuccess().send();
    }

    function getTotalWeeklyHoursWorked(){
        new ApiRequest('hr', 'getTotalWeeklyHoursWorked', () => {}, (response) => {
            setTotalWeeklyHoursWorked(parseFloat(response.hoursWorkedInWeek));
        }).withNoAlertOnSuccess().withData({date: selectedDate, companyIdentifier: selectedCompany}).sendInBackground()
    }

    function handleGoBackToHub() {
        setSelectedEmployee(undefined);
        loadData();
    }

    function sortUsers(a, b) {
        var nameA = `${a.lastName}${a.firstName}${a.middleName}`.toLowerCase();
        var nameB = `${b.lastName}${b.firstName}${b.middleName}`.toLowerCase();
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    }

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, gap: 12,  padding: 12}}>
                { isAdminNotShadowing && 
                    <>
                        <DropdownControl title='Select Company' selection={selectedCompany} setSelection={setSelectedCompany} itemNames={companies.map(c=>c.name)} itemValues={companies.map(c=>c.uid)}/>
                        { !!selectedCompany && 
                            <hr style={{margin: 0}}/>
                        }
                    </>
                }
                { (!!selectedCompany || !isAdminNotShadowing) && 
                    <>
                        { !!selectedEmployee ?
                            <HumanResourcesUser selectedEmployee={selectedEmployee} selectedCompany={selectedCompany} handleGoBackToHub={handleGoBackToHub}/>
                            :
                            <HRHub selectedEmployee={selectedEmployee} selectedCompany={selectedCompany} setSelectedEmployee={setSelectedEmployee} selectedDate={selectedDate} setSelectedDate={setSelectedDate} users={users} terminals={terminals} timeData={timeData} setTimeData={setTimeData} totalWeeklyHoursWorked={totalWeeklyHoursWorked}/>
                        }
                    </>
                }
            </div>
        </LoadingWrapper>
        
    )
}