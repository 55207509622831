/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ApiRequest } from '../../../../ApiManager.tsx';
import { availabilityToBinaryString, defaultOfferLetter, ptoAccrualTypeDictionary, validateDecimalFixed, payTypeDictionary, employeeTypeDictionary } from '../../../../tools';
import { Dropdown, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import RadioControl from '../../../../components/RadioControl.js';
import QuickTable from '../../../../components/QuickTable.js';
import CustomTextArea from '../../../../components/CustomStateControls/CustomTextArea.js';
import SwitchControl from '../../../../components/SwitchControl.js';
import DropdownItemWithCheck from '../../../../components/DropdownItemWithCheck.js';
import CustomButton from '../../../../components/CustomButton.js';
import BwcCodeControl from '../../../../components/BwcCodeControl.js';
import ChildSupportControl from '../../../../components/ChildSupportControl.js';
import AutomaticReimbursementControl from '../../../../components/AutomaticReimbursementControl.js';
import OfferLetterEditor from '../../../../components/OfferLetterEditor.js';
import { Validation, useStateWithValidation, useValidationReducer } from '../../../../validation.tsx';
import Cookies from 'universal-cookie';
import CustomToolTip from '../../../../components/CustomToolTip.js';
import AutomaticDeductionControl from '../../../../components/AutomaticDeductionControl.js';
import moment from 'moment';
import { useStateObjectWithValidation } from '../../../../hooks.tsx';
import { validationForArrays } from '../HRHire/HRHire.js';
import CustomControl from '../../../../components/CustomControl.js';

export default function HRUserPayEditor({companyUserIdentifier, handleAddPay, allPay}){
    const [company, setCompany] = useState({})
    const [signers, setSigners] = useState([]);
    const [terminal, setTerminal] = useState(null);
    const [offerLetters, setOfferLetters] = useState([])
    const [offerLetter, setOfferLetter] = useState(defaultOfferLetter)
    const [selectedOfferLetter, setSelectedOfferLetter] = useState(-1);
    const [showOfferLetter, setShowOfferLetter] = useState(false);
    const [user, setUser] = useState(null);
    const [sendOfferLetter, setSendOfferLetter] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [payPermissions, setPayPermissions] = useState([]);
    const [permissions, setPermissions] = useState([])
    const userPermissions = new Cookies().get('permissions');

    const [pay, setPay, setPayKey, payVMs, payIsValid] = useStateObjectWithValidation({
       dateEffective: '',
       payRate: '',
       hourlyWage: '',
       payType: undefined,
       employeeType: undefined,
       title: undefined,
       medical: '',
       dental: '',
       vision: '',
       bwcCode: '7231',
       ptoAccrual: '',
       ptoAccrualType: undefined,
       childSupport: [],
       automaticReimbursements: [],
       automaticDeductions: [],
       notes: '',
    },
    {
        dateEffective: Validation.date,
        payRate: Validation.greaterThanZero,
        hourlyWage: Validation.greaterThanZero,
        payType: Validation.notUndefined,
        employeeType: Validation.notUndefined,
        title: Validation.notUndefined,
        medical: Validation.nonEmptyString,
        dental: Validation.nonEmptyString,  
        vision: Validation.nonEmptyString, 
        bwcCode: Validation.nonEmptyString,
        ptoAccrual: Validation.nonEmptyString,
        ptoAccrualType: Validation.notUndefined,
        childSupport: (value) => validationForArrays(value, true), 
        automaticDeductions: (value) => validationForArrays(value, false),
        automaticReimbursements: (value) => validationForArrays(value, false),
        notes: Validation.none,
    });

    const [offerLetterRequired, setOfferLetterRequired, setOfferLetterRequiredKey, offerLetterRequiredVMs, offerLetterIsValid] = useStateObjectWithValidation({
        supervisorName: '',
        startTime: '08:00',
        dailyStartTime: '08:00',
        selectedAo: null,
        startDate: '',
    },
    {
        supervisorName: Validation.nonEmptyString,
        startTime: Validation.nonEmptyString,
        dailyStartTime: Validation.nonEmptyString,
        selectedAo: Validation.notUndefined,
        startDate: Validation.date,
    });
    
    useEffect(()=>{
        loadData();
    }, [])

    function loadData(){
        new ApiRequest('hr', 'getCompanyAndOfferLetters', setIsLoading, (response) => {
            response.offerLetters.forEach(ol=>{
                const associatedDefaults = [];
                if(response.company.defaultOfferLetterForDriver == ol.uid)
                    associatedDefaults.push('Driver')
                if(response.company.defaultOfferLetterForBc == ol.uid)
                    associatedDefaults.push('BC')
                if(response.company.defaultOfferLetterForAdmin == ol.uid)
                    associatedDefaults.push('Admin')

                ol.associatedDefaults = associatedDefaults;
            })
            setOfferLetters(response.offerLetters);
            setSigners(response.signerCandidates);
            setTerminal(response.terminal);
            setCompany(response.company);
            setUser(response.user);
            setPermissions(response.template);
        }).withData({companyUserIdentifier: companyUserIdentifier}).withNoAlertOnSuccess().send();
    }

    function handleCreateUserPay(){
        let offerLetterToSend = {};
        const newPay = {
            dateEffective: pay.dateEffective,
            title: pay.title,
            payType: pay.payType,
            payRate: pay.payRate,
            hourlyWage: validateDecimalFixed(pay.hourlyWage, 2),
            notes: pay.notes,
            employeeType: pay.employeeType,
            medical: validateDecimalFixed(pay.medical, 2),
            dental: validateDecimalFixed(pay.dental, 2),
            vision: validateDecimalFixed(pay.vision, 2),
            childSupport: pay.childSupport,
            bwcCode: pay.bwcCode,
            ptoAccrual: validateDecimalFixed(pay.ptoAccrual, 3),
            ptoAccrualType: pay.ptoAccrualType,
            automaticReimbursements: pay.automaticReimbursements,
            automaticDeductions: pay.automaticDeductions,
            sendOfferLetter: sendOfferLetter
        };

        if (sendOfferLetter) {
            const ao = signers.find(s => s.uid == offerLetterRequired.selectedAo);
            offerLetterToSend = {
                title: `${user?.firstName} ${user?.lastName} Offer Letter`,
                content: offerLetter,
                companyName: company.name,
                employeeType: employeeTypeDictionary[pay.employeeType],
                startDate: offerLetterRequired.startDate,
                supervisorName: offerLetterRequired.supervisorName,
                terminalAddress: terminal.address,
                startTime: offerLetterRequired.startTime,
                payType: pay.payType,
                payRate: pay.payRate,
                expectedAvailability: availabilityToBinaryString(user.availability),
                dailyStartTime: offerLetterRequired.dailyStartTime,
                benefits: company.benefits,
                aoName: `${ao.firstName} ${ao.lastName}`,
                aoPhoneNumber: ao.phoneNumber,
                aoEmail: ao.email,
            };
        }
        new ApiRequest('hr', 'createPay', setIsSubmitting, (response) => {
            newPay.uid = response.uid; handleAddPay(newPay);
        }).withData({pay: newPay, offerLetter: offerLetterToSend, companyUserIdentifier: companyUserIdentifier, permissions: payPermissions}).send();
    }

    function handlePrefill(pay){
        setPay({
            dateEffective: pay.dateEffective,
            title: pay.title,
            payRate: pay.payRate,
            hourlyWage: pay.hourlyWage,
            payType: pay.payType,
            employeeType: pay.employeeType,
            medical: pay.medical,
            dental: pay.dental,
            vision: pay.vision,
            bwcCode: pay.bwcCode,
            ptoAccrual: pay.ptoAccrual,
            ptoAccrualType: pay.ptoAccrualType,
            childSupport: pay.childSupport,
            automaticReimbursements: pay.automaticReimbursements,
            automaticDeductions: pay.automaticDeductions,
            notes: pay.notes,
        })
    }

    function handlePermissionChange(permissionName) {
        if(payPermissions.includes(permissionName)){
            setPayPermissions(payPermissions.filter(p=> p !== permissionName));
        }else{
            setPayPermissions([...payPermissions, permissionName])
        }
    }

    function handleUseTemplate(selectedTemplate) {
        const newPermissions = [];
        permissions.forEach(permission => {if(permission[selectedTemplate.toLocaleLowerCase()]){newPermissions.push(permission.permissionName)}});
        setPayPermissions(newPermissions);
    }

    const allPayDropdownItems = allPay.map((p) => {
        return (
            <Dropdown.Item key={p.uid} onClick={() => {handlePrefill(p)}}>
                {`${moment(p.dateEffective).format('MMM D, YYYY')} - ${p.title}`}
            </Dropdown.Item>
        )
    })

    const permissionRows = permissions.map((p) => {
        return (
            <tr key={p.permissionName}>
                <td>
                    <span style={{marginRight: 8}}>{p.permissionTitle}</span>
                    <CustomToolTip text={p.description}/>
                </td>
                <td>
                    <Form.Check type='switch' checked={payPermissions.includes(p.permissionName)} onChange={() => handlePermissionChange(p.permissionName)}/>
                </td>
            </tr>
        )
    });

    const offerLetterAos = signers.filter(s => s.title === 'AO');
    const offerLetterElements = offerLetters.map((ol) => {
        return (
            <Dropdown.Item key={ol.uid} onClick={() => {setOfferLetter(ol.content); setSelectedOfferLetter(ol.uid)}}>{ol.name}</Dropdown.Item>
        )
    })
    
    const bcRecommendationPopover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                <p style={{fontWeight:'bold'}}>Pay Tips</p>
                <ul>
                    <li>BC's recommended Pay Type is Per Year</li>
                    <li>Employees paid Per Year may only qualify for FLSA overtime if their Pay Rate is $35568.00 or below</li>
                </ul>
            </Popover.Body>
        </Popover>
    )
    
    const bcOverlay = (
        <OverlayTrigger  overlay={bcRecommendationPopover} placement="bottom">
            <FontAwesomeIcon size='lg' style={{color:'darkorange'}} icon={faCircleInfo}/>
        </OverlayTrigger>
    )
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>
                    Creating Pay
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                        <Dropdown style={{alignSelf: 'flex-end'}}>
                            <Dropdown.Toggle style={{display: 'block', marginLeft: 'auto', marginBottom: 12}} variant='outline-primary'>Prefill With Existing Pay</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Header>All Pay</Dropdown.Header>
                                {allPayDropdownItems}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                            <Col xxl={12}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <p style={{margin: 0, padding: 6}}>Pay Details</p>
                                    <div style={{display:'flex', justifyContent:'flex-end', padding:5}}>
                                        {bcOverlay}
                                    </div>
                                </div>
                            </Col>
                            <CustomControl validate validationMessage={payVMs.dateEffective} breakpoints={{lg: 4}} title='Date Effective' type='date' disabled={isLoading} value={pay.dateEffective} setValue={(value) => setPayKey('dateEffective', value)}/>
                            <CustomControl validate validationMessage={payVMs.payRate} breakpoints={{lg: 4}} title='Pay Rate' type='number'  disabled={isLoading} max={999999.99} value={pay.payRate} setValue={(value) => setPayKey('payRate', value)}/>
                            <CustomControl validate validationMessage={payVMs.hourlyWage} breakpoints={{lg: 4}} title='Overtime Rate'type='number' disabled={isLoading} max={999.99} value={pay.hourlyWage} setValue={(value) => setPayKey('hourlyWage', value)}/>
                            <Col xxl={12} style={{ marginBottom: 8 }}>
                                <RadioControl labelStyle={{width: 140}} disabled={isLoading} title='Pay Type' inline selection={pay.payType} validate validationMessage={payVMs.payType} setSelection={(value) => setPayKey('payType', value)} optionNames={Object.values(payTypeDictionary)} optionValues={Object.keys(payTypeDictionary)}/>
                            </Col>
                            <Col lg={12} style={{ marginBottom: 8 }}>
                                <RadioControl labelStyle={{width: 140}} disabled={isLoading} title='Employee Type' inline validate validationMessage={payVMs.employeeType} selection={pay.employeeType} setSelection={(value) => setPayKey('employeeType', value)} optionNames={['Full-Time', 'Part-Time']} optionValues={[0, 1]}/>
                            </Col>
                            <Col lg={12} style={{ marginBottom: 8 }}>
                                <RadioControl labelStyle={{width: 140}} disabled={isLoading} title='Title' inline validate validationMessage={payVMs.title} selection={pay.title} setSelection={(value) => setPayKey('title', value)} optionNames={['Driver', 'BC', 'Admin']} optionValues={['Driver', 'BC', 'Admin']}/>
                            </Col>
                            <BwcCodeControl defaultCustom labelStyle={{width: 140}} disabled={isLoading} inline validate validationMessage={payVMs.bwcCode} value={pay.bwcCode} setValue={(value) => setPayKey('bwcCode', value)} lg={12}/>
                        </Row>
                        <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                            <Col xxl={12}>
                                <p style={{margin: 0, padding: 6}}>PTO</p>
                            </Col>
                            <CustomControl validate validationMessage={payVMs.ptoAccrual} breakpoints={{lg: 12}} title='PTO Accrual (In Hours)' type='number' disabled={isLoading} max={99.999} value={pay.ptoAccrual} setValue={(value) => setPayKey('ptoAccrual', value)}/>
                            <RadioControl breakpoints={{lg: 12}} title='Accrual Frequency' inline validate validationMessage={payVMs.ptoAccrualType} selection={pay.ptoAccrualType} setSelection={(value) => setPayKey('ptoAccrualType', value)} optionNames={Object.values(ptoAccrualTypeDictionary)} optionValues={[0, 1]}/>
                        </Row>
                        <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                            <Col xxl={12}><p style={{margin: 0, padding: 6}}>Deductions</p></Col>
                            <CustomControl validate validationMessage={payVMs.medical} breakpoints={{lg: 4}} title='Medical Insurance' type='number' disabled={isLoading} max={9999.99} value={pay.medical} setValue={(value) => setPayKey('medical', value)}/>
                            <CustomControl validate validationMessage={payVMs.dental} breakpoints={{lg: 4}} title='Dental Insurance' type='number' disabled={isLoading} max={9999.99} value={pay.dental} setValue={(value) => setPayKey('dental', value)}/>
                            <CustomControl validate validationMessage={payVMs.vision} breakpoints={{lg: 4}} title='Vision Insurance' type='number' disabled={isLoading} max={9999.99} value={pay.vision} setValue={(value) => setPayKey('vision', value)}/>
                            <ChildSupportControl validationMessages={payVMs.childSupport} breakpoints={{lg: 12}} value={pay.childSupport} setValue={(value) => setPayKey('childSupport', value)}/>
                            <AutomaticDeductionControl validationMessages={payVMs.automaticDeductions} breakpoints={{lg: 12}} value={pay.automaticDeductions} setValue={(value) => setPayKey('automaticDeductions', value)}/>
                        </Row>
                        <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                            <Col xxl={12}>
                                <p style={{margin: 0, padding: 6}}>Reimbursements</p>
                            </Col>
                            <AutomaticReimbursementControl validationMessages={payVMs.automaticReimbursements} breakpoints={{lg: 12}} value={pay.automaticReimbursements} setValue={(value) => setPayKey('automaticReimbursements', value)}/>
                        </Row>
                        <CustomTextArea breakpoints={{xxl: 12}} placeholder='Notes' disabled={isLoading} rows={4} max={200} value={pay.notes} setValue={(value) => setPayKey('notes', value)}/>
                        <hr/>
                        <Row style={{border: '1px solid lightgray', borderRadius: 6, padding: 6, margin: 0, marginBottom: 18}}>
                            <Col xxl={12}><p style={{margin: 0, padding: 6}}>Permissions</p></Col>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12}}>
                                <div style={{display: 'flex', flexWrap: 'wrap', gap: 12, alignItems: 'center', border: '1px solid lightgray', borderRadius: 6, padding: 8, width: 'fit-content'}}>
                                    <span>Default Permission Templates</span>
                                    <div style={{display: 'flex', gap: 8}}>
                                        { permissions.reduce((prev, curr) => {return prev && userPermissions.includes(curr.permissionName)}, true) && 
                                            <Button variant={'outline-primary'} onClick={() => handleUseTemplate('AO')}>Authorized Officer</Button>
                                        }
                                        { permissions.reduce((prev, curr) => {return prev && (userPermissions.includes(curr.permissionName) || curr.permissionName == 'hr_permissions' || curr.permissionName == 'reports')}, true) && 
                                            <Button variant={'outline-primary'} onClick={() =>  handleUseTemplate('BC')}>Business Contact</Button>
                                        }
                                    </div>
                                </div>
                                
                            </div>
                            <QuickTable responsive bordered striped rows={[permissionRows]} headers={['Permission', '']}/>
                        </Row>
                            <hr/>
                            <Row>
                                <InputGroup style={{justifyContent: 'center', margin: '20px 0'}}>
                                    <SwitchControl style={{flex: 1}} title='Send Offer Letter?' value={sendOfferLetter} setValue={setSendOfferLetter}/>
                                    {sendOfferLetter &&
                                        <>
                                            <Dropdown>
                                                <Dropdown.Toggle variant='outline-primary'>
                                                {`Offer Letter: ${selectedOfferLetter == -1 ? 'Default' : offerLetters.find(ol=>ol.uid == selectedOfferLetter)?.name ?? 'Custom'}`}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                <DropdownItemWithCheck active={selectedOfferLetter == -1} label='TTA Default' onClick={()=>{setSelectedOfferLetter(-1); setOfferLetter(defaultOfferLetter)}}/>
                                                {offerLetters.map(ol=>{
                                                    return <DropdownItemWithCheck key={ol.uid} active={selectedOfferLetter == ol.uid} label={`${ol.name}${ol.associatedDefaults.length ? ` (${ol.associatedDefaults.join(', ')})` : ''}`} onClick={()=>{setSelectedOfferLetter(ol.uid); setOfferLetter(ol.content)}}/>
                                                })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Button variant='outline-primary' onClick={() => {setShowOfferLetter(true)}}>
                                                <span>Edit Offer Letter</span>
                                            </Button>
                                        </>
                                    }
                                </InputGroup>
                                {sendOfferLetter && 
                                    <div style={{border: '1px solid lightgray', padding: 12, borderRadius: 6}}>
                                        <h5 style={{textAlign: 'center'}}>Required Information For Offer Letter</h5>
                                        <Container fluid style={{padding: 0}}>
                                            <Row>
                                                <CustomControl validate validationMessage={offerLetterRequiredVMs.startDate} breakpoints={{lg: 6}} title='Employment Start Date' type='date' value={offerLetterRequired.startDate} setValue={(value) => setOfferLetterRequiredKey('startDate', value)}/>
                                                <CustomControl validate validationMessage={offerLetterRequiredVMs.supervisorName} breakpoints={{lg: 6}} title='Name of Immediate Supervisor' value={offerLetterRequired.supervisorName} setValue={(value) => setOfferLetterRequiredKey('supervisorName', value)}/>
                                                <CustomControl validate validationMessage={offerLetterRequiredVMs.startTime} breakpoints={{lg: 6}} title='Start Time on First Day' type='time' value={offerLetterRequired.startTime} setValue={(value) => setOfferLetterRequiredKey('startTime', value)}/>
                                                <CustomControl validate validationMessage={offerLetterRequiredVMs.dailyStartTime} breakpoints={{lg: 6}} title='Daily Start Time' type='time' value={offerLetterRequired.dailyStartTime} setValue={(value) => setOfferLetterRequiredKey('dailyStartTime', value)}/>
                                                <RadioControl lg={12} title='AO to Appear on Offer Letter' validate validationMessage={offerLetterRequiredVMs.selectedAo} selection={offerLetterRequired.selectedAo} setSelection={(value) => setOfferLetterRequiredKey('selectedAo', value)} optionValues={offerLetterAos.map(ao => ao.uid)} optionNames={offerLetterAos.map(ao => `${ao.firstName} ${ao.lastName}`)} inline/>
                                            </Row>
                                        </Container>
                                    </div>
                                }
                            </Row>
                </LoadingWrapper>
            </Modal.Body>            
            <Modal.Footer>
                <div style={{display:'flex', alignItems:'center', gap:10}}>
                    <CustomButton disabled={!sendOfferLetter ? !payIsValid : (!payIsValid || !offerLetterIsValid)} isLoading={isSubmitting} onClick={handleCreateUserPay}>Submit</CustomButton>
                </div>
            </Modal.Footer>
            <Modal show={showOfferLetter} onHide={() => {setShowOfferLetter(false)}} size='xl' fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>Offer Letter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Dropdown.Toggle variant='outline-primary'>Import Template</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {offerLetterElements}
                        </Dropdown.Menu>
                    </Dropdown>
                    <h5 style={{textAlign: 'center', color: 'gray', fontStyle: 'italic', margin: 0}}>Any modifications will be reflected in the offer letter sent to the employee</h5>
                    <OfferLetterEditor value={offerLetter} setValue={setOfferLetter} setSelectedOfferLetter={setSelectedOfferLetter} offerLetterData={{
                        companyName: company?.name,
                        employeeName: `${user?.firstName} ${user?.lastName}`,
                        employeeType: pay.employeeType,
                        startDate: offerLetterRequired.startDate,
                        supervisorName: offerLetterRequired.supervisorName,
                        terminalAddress: terminal?.address,
                        startTime: offerLetterRequired.startTime,
                        payType: pay.payType,
                        payRate: validateDecimalFixed(pay.payRate, 2),
                        expectedAvailability: availabilityToBinaryString(user?.availability ?? ''),
                        dailyStartTime: offerLetterRequired.dailyStartTime,
                        benefits: company?.benefits,
                        aoName: `${signers.find(s => s.uid == offerLetterRequired.selectedAo)?.firstName ?? ''} ${signers.find(s => s.uid == offerLetterRequired.selectedAo)?.lastName ?? ''}`,
                        aoPhoneNumber: signers.find(s => s.uid == offerLetterRequired.selectedAo)?.phoneNumber ?? '',
                        aoEmail: signers.find(s => s.uid == offerLetterRequired.selectedAo)?.email ?? ''
                    }}/>     
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-primary' onClick={() => {setShowOfferLetter(false)}}>Done</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}