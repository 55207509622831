/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Popover } from 'react-bootstrap';

export default function CustomToolTip({text, icon, color, placement, onClick}) {
    return (
        <OverlayTrigger placement={placement} overlay={(
            <Popover style={{position: 'fixed'}}>
                <Popover.Body>
                    {text}
                </Popover.Body>
            </Popover>
        )}>
            <FontAwesomeIcon style={{color: color ?? 'var(--bs-primary)', cursor: onClick ? 'pointer' : 'default'}} icon={icon ?? faQuestionCircle} onClick={onClick}/>
        </OverlayTrigger>
    )
}