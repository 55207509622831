/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { defaultDropAnimationSideEffects, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { faArrowsAltV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import {CSS} from '@dnd-kit/utilities';

// Debug Component: Drag-and-Drop with sortable list
export default function Debug() {
    // State for the items to be sorted
    const [items, setItems] = useState(generateItems(10));  // Start with 10 items
    const [active, setActive] = useState(null)
    const activeItem = useMemo(
      () => items.find((item) => item.id === active?.id),
      [active, items]
    );
  
    // Setup sensors to detect pointer and keyboard events
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );
  
    // Drag Start Event
    const handleDragStart = ({ active }) => {
      console.log("Dragging started:", active.id);
      setActive(active)
    };
  
    // Drag End Event (reorder items)
    const handleDragEnd = ({ active, over }) => {
      if (over && active.id !== over.id) {
        const activeIndex = items.findIndex((item) => item.id === active.id);
        const overIndex = items.findIndex((item) => item.id === over.id);
        setItems(arrayMove(items, activeIndex, overIndex)); // Reorder items in the state
      }
    };

    function renderItem(item){
      return (
          <SortableItem item={item} />
      )
  }
  
    return (
      <DndContext
        // sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={() => {}}
        onDragCancel={() => console.log("Drag canceled")}
      >
        <div style={{ maxWidth: 400, margin: "30px auto", padding: 20 }}>
          <h2 style={{ textAlign: "center" }}>Sortable List Debug</h2>
  
          <SortableContext items={items} >
            <ul
              style={{
                listStyleType: "none",
                paddingLeft: 0,
                margin: 0,
                fontFamily: "Arial, sans-serif",
              }}
            >
              {items.map((item) => (
                <SortableItem key={item.id} item={item} />
              ))}
            </ul>
          </SortableContext>
  
          {/* The overlay used during the drag */}
          <SortableOverlay >
            {activeItem ? renderItem(activeItem) : null}
          </SortableOverlay>
        </div>
      </DndContext>
    );
  }
  
  // Generate mock items for testing
  function generateItems(count) {
    return Array.from({ length: count }, (_, index) => ({
      id: `item-${index + 1}`,
      content: `Item #${index + 1}`,
    }));
  }
  
  // Sortable Item Component
  function SortableItem({ item }) {
    const { attributes, listeners, setNodeRef, isDragging, transform, transition } = useSortable({
      id: item.id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: '10px',
        margin: '5px',
        border: '1px solid #ccc',
        backgroundColor: isDragging ? '#ddd' : '#f9f9f9',
        cursor: 'move',
      };
  
    return (
      <li
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={style}
      >
        <span>{item.content}</span>
        <FontAwesomeIcon icon={faArrowsAltV} style={{ marginLeft: 10, cursor: "move" }} />
      </li>
    );
  }
  
  // Sortable Overlay with Drop Animation
  const dropAnimationConfig = {
    sideEffects: defaultDropAnimationSideEffects({
      styles: {
        active: {
          opacity: "0.4",
        },
      },
    }),
  };
  
  export function SortableOverlay({ children }) {
    return <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>;
  }