

/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import Decoder, { DecodingError } from "../../../../decoding";
import Address from "../../../../models/Address";
import StateObject from "../../../../state/StateObject.tsx";
import { validateDecimal, validateDecimalFixed, validateInteger } from "../../../../tools";

export default class Company extends StateObject{
    uid;
    name;
    ein;
    address;
    benefits;
    isEnabled;
    driverSchedulePreviewDays;
    companyAddressAllowClockIn;
    companyAddressAllowClockInRadius;
    enableGeofencing;
    restrictWhoSeesPay;
    showBcsPayrollHistory;
    payrollEnabled;
    bcPayrollEnabled;
    restrictBcPayrollToTerminals;
    payrollPeriodLength;
    payrollPeriodStartDayIndex;
    payrollStartDate;
    requestOffPerDayLimit;
    requestOffPerTerminalLimit;
    allowNegativePto;
    autoIncentiveWageThreshold;
    autoIncentiveWageAmount;
    autoOvertimeThreshold;
    autoOvertimeAmount;
    includeAOsInPayroll;
    generatePayrollForEachTerminal;
    defaultOfferLetterForDriver;
    defaultOfferLetterForBc;
    defaultOfferLetterForAdmin;
    clockInReminder;
    require1EContact;
    nighttimeThreshold;
    nighttimeBonus;
    weekendBonus;
    newHireNotificationsEnabled;

    constructor(
        uid,
        name,
        ein,
        address,
        benefits,
        isEnabled,
        driverSchedulePreviewDays,
        companyAddressAllowClockIn,
        companyAddressAllowClockInRadius,
        enableGeofencing,
        restrictWhoSeesPay,
        showBcsPayrollHistory,
        payrollEnabled,
        bcPayrollEnabled,
        restrictBcPayrollToTerminals,
        payrollPeriodLength,
        payrollPeriodStartDayIndex,
        payrollStartDate,
        requestOffPerDayLimit,
        requestOffPerTerminalLimit,
        allowNegativePto,
        autoIncentiveWageThreshold,
        autoIncentiveWageAmount,
        autoOvertimeThreshold,
        autoOvertimeAmount,
        includeAOsInPayroll,
        generatePayrollForEachTerminal,
        defaultOfferLetterForDriver,
        defaultOfferLetterForBc,
        defaultOfferLetterForAdmin,
        clockInReminder,
        require1EContact,
        nighttimeThreshold,
        nighttimeBonus,
        weekendBonus,
        newHireNotificationsEnabled,
    ){
        super();
        this.uid = uid;
        this.name = name;
        this.ein = ein;
        this.address = address;
        this.benefits = benefits;
        this.isEnabled = isEnabled;
        this.driverSchedulePreviewDays = driverSchedulePreviewDays;
        this.companyAddressAllowClockIn = companyAddressAllowClockIn;
        this.companyAddressAllowClockInRadius = companyAddressAllowClockInRadius;
        this.enableGeofencing = enableGeofencing;
        this.restrictWhoSeesPay = restrictWhoSeesPay;
        this.showBcsPayrollHistory = showBcsPayrollHistory;
        this.payrollEnabled = payrollEnabled;
        this.bcPayrollEnabled = bcPayrollEnabled;
        this.restrictBcPayrollToTerminals = restrictBcPayrollToTerminals;
        this.payrollPeriodLength = payrollPeriodLength;
        this.payrollPeriodStartDayIndex = payrollPeriodStartDayIndex;
        this.payrollStartDate = payrollStartDate;
        this.requestOffPerDayLimit = requestOffPerDayLimit;
        this.requestOffPerTerminalLimit = requestOffPerTerminalLimit;
        this.allowNegativePto = allowNegativePto;
        this.autoIncentiveWageThreshold = autoIncentiveWageThreshold;
        this.autoIncentiveWageAmount = autoIncentiveWageAmount;
        this.autoOvertimeThreshold = autoOvertimeThreshold;
        this.autoOvertimeAmount = autoOvertimeAmount;
        this.includeAOsInPayroll = includeAOsInPayroll;
        this.generatePayrollForEachTerminal = generatePayrollForEachTerminal;
        this.defaultOfferLetterForDriver = defaultOfferLetterForDriver;
        this.defaultOfferLetterForBc = defaultOfferLetterForBc;
        this.defaultOfferLetterForAdmin = defaultOfferLetterForAdmin;
        this.clockInReminder = clockInReminder;
        this.require1EContact = require1EContact;
        this.nighttimeThreshold = nighttimeThreshold;
        this.nighttimeBonus = nighttimeBonus;
        this.weekendBonus = weekendBonus;
        this.newHireNotificationsEnabled = newHireNotificationsEnabled;
        this.initChildren();
    }

    static initDefault(){
        return new Company(
            -1,
            '',
            '',
            Address.initDefault(),
            '',
            true,
            7,
            false,
            0,
            false,
            false,
            false,
            false,
            false,
            7,
            0,
            0,
            moment().format('YYYY-MM-DD'),
            null,
            null,
            false,
            0,
            null,
            0,
            null,
            false,
            false,
            -1,
            -1,
            -1,
            moment().format('YYYY-MM-DD'),
            false,
            '20:00:00',
            null,
            null,
            0
        )
    }

    static decode(json){
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const name = decoder.decode('name', Decoder.NonEmptyString);
        const ein = decoder.decode('ein', Decoder.String);
        const address = Address.decode(json.address);
        const benefits = decoder.decode('benefits', Decoder.String);
        const isEnabled = decoder.decode('isEnabled', Decoder.BooleanFlexible);
        const driverSchedulePreviewDays = decoder.decode('driverSchedulePreviewDays', Decoder.Integer);
        const companyAddressAllowClockIn = decoder.decode('companyAddressAllowClockIn', Decoder.BooleanFlexible);
        const companyAddressAllowClockInRadius = decoder.decode('companyAddressAllowClockInRadius', Decoder.Integer);
        const enableGeofencing = decoder.decode('enableGeofencing', Decoder.BooleanFlexible);
        const restrictWhoSeesPay = decoder.decode('restrictWhoSeesPay', Decoder.BooleanFlexible);
        const showBcsPayrollHistory = decoder.decode('showBcsPayrollHistory', Decoder.BooleanFlexible);
        const payrollEnabled = decoder.decode('payrollEnabled', Decoder.BooleanFlexible);
        const bcPayrollEnabled = decoder.decode('bcPayrollEnabled', Decoder.BooleanFlexible);
        const restrictBcPayrollToTerminals = decoder.decode('restrictBcPayrollToTerminals', Decoder.BooleanFlexible);
        const payrollPeriodLength = decoder.decode('payrollPeriodLength', Decoder.Integer);
        const payrollPeriodStartDayIndex = decoder.decode('payrollPeriodStartDayIndex', Decoder.Integer);
        const payrollStartDate = decoder.decode('payrollStartDate', Decoder.Date, {defaultValue: null, warn: false});
        const requestOffPerDayLimit = decoder.decode('requestOffPerDayLimit', Decoder.Integer, {defaultValue: null, warn: false});
        const requestOffPerTerminalLimit = decoder.decode('requestOffPerTerminalLimit', Decoder.Integer, {defaultValue: null, warn: false});
        const allowNegativePto = decoder.decode('allowNegativePto', Decoder.BooleanFlexible);
        const autoIncentiveWageThreshold = decoder.decode('autoIncentiveWageThreshold', Decoder.Integer);
        const autoIncentiveWageAmount = decoder.decode('autoIncentiveWageAmount', Decoder.Decimal, {defaultValue: null, warn: false});
        const autoOvertimeThreshold = decoder.decode('autoOvertimeThreshold', Decoder.Integer);
        let autoOvertimeAmount = decoder.decode('autoOvertimeAmount', Decoder.Decimal, {defaultValue: null, warn: false});
        autoOvertimeAmount = autoOvertimeAmount ? autoOvertimeAmount*100 : null;
        const includeAOsInPayroll = decoder.decode('includeAOsInPayroll', Decoder.BooleanFlexible);
        const generatePayrollForEachTerminal = decoder.decode('generatePayrollForEachTerminal', Decoder.BooleanFlexible);
        const defaultOfferLetterForDriver = decoder.decode('defaultOfferLetterForDriver', Decoder.Uid, {defaultValue: null, warn: false});
        const defaultOfferLetterForBc = decoder.decode('defaultOfferLetterForBc', Decoder.Uid, {defaultValue: null, warn: false});
        const defaultOfferLetterForAdmin = decoder.decode('defaultOfferLetterForAdmin', Decoder.Uid, {defaultValue: null, warn: false});
        const clockInReminder = decoder.decode('clockInReminder', Decoder.NonEmptyString, {defaultValue: null, warn: false})
        const require1EContact = decoder.decode('require1EContact', Decoder.BooleanFlexible);
        const nighttimeThreshold = decoder.decode('nighttimeThreshold', Decoder.String);
        const nighttimeBonus = decoder.decode('nighttimeBonus', Decoder.Decimal, {defaultValue: null, warn: false});
        const weekendBonus = decoder.decode('weekendBonus', Decoder.Decimal, {defaultValue: null, warn: false});
        const newHireNotificationsEnabled = decoder.decode('newHireNotificationsEnabled', Decoder.BooleanFlexible);

        if(decoder.checkForErrors()){
            return new Company( uid, name, ein, address, benefits, isEnabled, driverSchedulePreviewDays, companyAddressAllowClockIn, companyAddressAllowClockInRadius, enableGeofencing, restrictWhoSeesPay, 
                showBcsPayrollHistory, payrollEnabled, bcPayrollEnabled, restrictBcPayrollToTerminals, payrollPeriodLength, payrollPeriodStartDayIndex, payrollStartDate, requestOffPerDayLimit, requestOffPerTerminalLimit, allowNegativePto, 
                autoIncentiveWageThreshold, autoIncentiveWageAmount, autoOvertimeThreshold, autoOvertimeAmount, includeAOsInPayroll, generatePayrollForEachTerminal, defaultOfferLetterForDriver, defaultOfferLetterForBc, defaultOfferLetterForAdmin, clockInReminder, require1EContact,
                nighttimeThreshold, nighttimeBonus, weekendBonus, newHireNotificationsEnabled
            )
        } else{
            return Company.initDefault()
        }
    }

    duplicate(){
        return new Company(
            this.uid,
            this.name,
            this.ein,
            this.address,
            this.benefits,
            this.isEnabled,
            this.driverSchedulePreviewDays,
            this.companyAddressAllowClockIn,
            this.companyAddressAllowClockInRadius,
            this.enableGeofencing,
            this.restrictWhoSeesPay,
            this.showBcsPayrollHistory,
            this.payrollEnabled,
            this.bcPayrollEnabled,
            this.restrictBcPayrollToTerminals,
            this.payrollPeriodLength,
            this.payrollPeriodStartDayIndex,
            this.payrollStartDate,
            this.requestOffPerDayLimit,
            this.requestOffPerTerminalLimit,
            this.allowNegativePto,
            this.autoIncentiveWageThreshold,
            this.autoIncentiveWageAmount,
            this.autoOvertimeThreshold,
            this.autoOvertimeAmount,
            this.includeAOsInPayroll,
            this.generatePayrollForEachTerminal,
            this.defaultOfferLetterForDriver,
            this.defaultOfferLetterForBc,
            this.defaultOfferLetterForAdmin,
            this.clockInReminder,
            this.require1EContact,
            this.nighttimeThreshold,
            this.nighttimeBonus,
            this.weekendBonus,
            this.newHireNotificationsEnabled,
        )
    }

    encode(){
        return {
            uid: this.uid,
            name: this.name,
            ein: validateInteger(this.ein),
            address: this.address.encode(),
            benefits: this.benefits,
            isEnabled: this.isEnabled,
            driverSchedulePreviewDays: validateInteger(this.driverSchedulePreviewDays),
            companyAddressAllowClockIn: this.companyAddressAllowClockIn,
            companyAddressAllowClockInRadius: validateInteger(this.companyAddressAllowClockInRadius),
            enableGeofencing: this.enableGeofencing,
            restrictWhoSeesPay: this.restrictWhoSeesPay,
            showBcsPayrollHistory: this.showBcsPayrollHistory,
            payrollEnabled: this.payrollEnabled,
            bcPayrollEnabled: this.bcPayrollEnabled,
            restrictBcPayrollToTerminals: this.restrictBcPayrollToTerminals,
            payrollPeriodLength: this.payrollPeriodLength,
            payrollPeriodStartDayIndex: this.payrollPeriodStartDayIndex,
            payrollStartDate: this.payrollStartDate,
            requestOffPerDayLimit: this.requestOffPerDayLimit ? validateInteger(this.requestOffPerDayLimit) : null,
            requestOffPerTerminalLimit: this.requestOffPerTerminalLimit ? validateInteger(this.requestOffPerTerminalLimit) : null,
            allowNegativePto: this.allowNegativePto,
            autoIncentiveWageThreshold: validateInteger(this.autoIncentiveWageThreshold),
            autoIncentiveWageAmount: this.autoIncentiveWageAmount ? validateDecimalFixed(this.autoIncentiveWageAmount, 2) : null,
            autoOvertimeThreshold: validateInteger(this.autoOvertimeThreshold),
            autoOvertimeAmount: this.autoOvertimeAmount ? validateDecimal(this.autoOvertimeAmount/100) : null,
            includeAOsInPayroll: this.includeAOsInPayroll,
            generatePayrollForEachTerminal: this.generatePayrollForEachTerminal,
            defaultOfferLetterForDriver: this.defaultOfferLetterForDriver,
            defaultOfferLetterForBc: this.defaultOfferLetterForBc,
            defaultOfferLetterForAdmin: this.defaultOfferLetterForAdmin,
            clockInReminder: this.clockInReminder,
            require1EContact: this.require1EContact,
            nighttimeThreshold: this.nighttimeThreshold ? this.nighttimeThreshold : '20:00:00',
            nighttimeBonus: this.nighttimeBonus ? validateDecimal(this.nighttimeBonus) : null,
            weekendBonus: this.weekendBonus ? validateDecimal(this.weekendBonus) : null,
            newHireNotificationsEnabled: this.newHireNotificationsEnabled,
        }
    }
}