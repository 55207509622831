/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { createRef, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import moment from "moment";
import { employeeTypeDictionary, payTypeDictionary, stateDictionary, validateDecimal, validateInteger } from "../../../../../tools";
import { ApiRequest } from "../../../../../ApiManager.tsx";
import onboardingTemplate from '../../../../../assets/OnboardingTemplateLinehaul.xlsx';
import QuickTable from "../../../../../components/QuickTable.js";
import CustomButton from "../../../../../components/CustomButton.js";
import { Dropdown, InputGroup } from "react-bootstrap";
import { Validation } from "../../../../../validation.tsx";

export default function HRHireCSV({terminals}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [fileError, setFileError] = useState(undefined);
    const fileRef = useRef();

    async function handleFileChange() {
        const file = fileRef.current.files[0];
        if (file) {
            const indexOfDot = file.name.lastIndexOf('.');
            const fileType = file.name.slice(indexOfDot + 1);
            if (fileType !== 'csv') {
                setFileError('Invalid file type. Please upload a .csv file.')
                return;
            } else if (fileError) {
                setFileError(undefined);
            }
            
            const employeeArray = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onerror = () => {}
                reader.onload = () => {
                    let str = reader.result;
                    let isOn = false;
                    for (let i = 0; i < str.length; i++) {
                        if (str.substring(i, i + 1) == "\"") {
                            isOn = !isOn;
                        } else if (str[i] == ',' && isOn) {
                            str = `${str.substring(0, i)}${str.substring(i + 1)}`
                        }
                    }
                    str = str.replaceAll('\"', '')
                    let rows = str.split(/\r\n|\r|\n/);
                    rows = rows.slice(5);
                    for (let i = 0; i < rows.length; i++) {
                        rows[i] = rows[i].split(',');
                        rows[i] = rows[i].slice(0, 18);
                    }
                    rows = rows.filter(r => r.find(c => c)); //filters out rows which have no data in any cell
                    const employees = [];
                    for (let i = 0; i < rows.length; i++) {
                        const row = rows[i];
                        const terminal = terminals.find(terminal => terminal.name.toLowerCase() === row[13].toLowerCase());
                        const employee = {
                            firstName: row[0] ?? '',
                            middleName: row[1] ?? '',
                            lastName: row[2] ?? '',
                            email: row[3] ?? '', 
                            phoneNumber: row[4] ?? '', 
                            dateOfBirth: moment(row[5], 'M/DD/YYYY').isValid() ? moment(row[5], 'M/DD/YYYY').format('YYYY-MM-DD') : '', 
                            ssn: row[6] ?? '', 
                            thoroughfare: row[7] ?? '', 
                            premise: row[8] ?? '', 
                            administrativeArea: Object.values(stateDictionary).includes(row[9]) ? row[9] : '', 
                            locality: row[10] ?? '', 
                            postalCode: row[11] ?? '',
                            payType: row[12] ?? '',
                            terminalIdentifier: terminal?.uid,
                            employeeType: row[14] === 'Full-Time' ? '0' : row[14] === 'Part-Time' ?  '1' : '',
                            title: row[15] ?? '',
                            payRate: row[16] ?? '',
                            hourlyWage: row[17] ?? '',
                            availability: '1111111',
                            bwcCode: '7231',
                            ptoAccrual: 0,
                            ptoAccrualType: 0,
                            medical: 0,
                            dental: 0,
                            vision: 0,
                            notes: '',

                        } 
                        employees.push(employee);
                    }
                    resolve(employees);
                }
                reader.readAsText(file);
            });
            setEmployees(employeeArray);
        }
    }

    function handleSubmit(){
        const mappedEmployees = employees.map((e) => {
            return {...e, payRate: validateDecimal(e.payRate), hourlyWage: validateDecimal(e.hourlyWage), address: {
                thoroughfare: e.thoroughfare,
                premise: e.premise,
                administrativeArea: e.administrativeArea,
                locality: e.locality,
                postalCode: validateInteger(e.postalCode),
                latitude: 0,
                longitude: 0
            }}
        })
        new ApiRequest('hr', 'csvHire', setIsSubmitting, () => window.location.reload()).withData({employees: mappedEmployees}).send();
    }

    function handleSetEmployee(index, key, value){
        const newEmployees = Array.from(employees);
        newEmployees[index][key] = value;
        setEmployees(newEmployees);
    }

    const employeeRows = employees.map((employee, index) => {
        employee.isValid = false;
        if(
            !Validation.nonEmptyString(employee.firstName) &&
            !Validation.none(employee.middleName) &&
            !Validation.nonEmptyString(employee.lastName) &&
            !Validation.email(employee.email) &&
            !Validation.phoneNumber(employee.phoneNumber) &&
            !Validation.date(employee.dateOfBirth) &&
            !Validation.ssn(employee.ssn) &&
            !Validation.nonEmptyString(employee.payType) &&
            !Validation.nonEmptyString(employee.terminalIdentifier) &&
            !Validation.nonEmptyString(employee.employeeType) &&
            !Validation.nonEmptyString(employee.title) &&
            !Validation.greaterThanZero(employee.payRate) &&
            !Validation.greaterThanZero(employee.hourlyWage)
        ){
            employee.isValid = true;
        }

        return (
            <tr key={index}>
                <td>
                    <TableField value={employee.firstName} setValue={(value) => {handleSetEmployee(index, 'firstName', value)}} max={30} validation={Validation.nonEmptyString}/>
                </td>
                <td>
                    <TableField value={employee.middleName} setValue={(value) => {handleSetEmployee(index, 'middleName', value)}}  max={30}validation={Validation.none}/>
                </td>
                <td>
                    <TableField value={employee.lastName} setValue={(value) => {handleSetEmployee(index, 'lastName', value)}} max={30} validation={Validation.nonEmptyString}/>
                </td>
                <td>
                    <TableField value={employee.email} setValue={(value) => {handleSetEmployee(index, 'email', value)}} validation={Validation.email}/>
                </td>
                <td>
                    <TableField value={employee.phoneNumber} setValue={(value) => {handleSetEmployee(index, 'phoneNumber', value)}} type='number' max={9999999999} validation={Validation.phoneNumber}/>
                </td>
                <td>
                    <TableField value={employee.dateOfBirth} setValue={(value) => {handleSetEmployee(index, 'dateOfBirth', value)}} type='date' validation={Validation.date}/>
                </td>
                <td>
                    <TableField value={employee.ssn} setValue={(value) => {handleSetEmployee(index, 'ssn', value)}} max={9} validation={Validation.ssn}/>
                </td>
                <td>
                    <TableField value={employee.thoroughfare} setValue={(value) => {handleSetEmployee(index, 'thoroughfare', value)}} max={80} validation={Validation.none}/>
                </td>
                <td>
                    <TableField value={employee.premise} setValue={(value) => {handleSetEmployee(index, 'premise', value)}} max={30} validation={Validation.none}/>
                </td>
                <td>
                    <TablePicker value={employee.administrativeArea} setValue={(value) => {handleSetEmployee(index, 'administrativeArea', value)}} itemNames={Object.keys(stateDictionary)} itemValues={Object.values(stateDictionary)} validation={Validation.none}/>
                </td>
                <td>
                    <TableField value={employee.locality} setValue={(value) => {handleSetEmployee(index, 'locality', value)}} max={20} validation={Validation.none}/>
                </td>
                <td>
                    <TableField value={employee.postalCode} setValue={(value) => {handleSetEmployee(index, 'postalCode', value)}} max={99999} type='number' validation={Validation.none}/>
                </td>
                <td>
                    <TablePicker value={employee.payType} setValue={(value) => {handleSetEmployee(index, 'payType', value)}} itemNames={Object.values(payTypeDictionary)} itemValues={Object.keys(payTypeDictionary)} validation={Validation.nonEmptyString}/>
                </td>
                <td>
                    <TablePicker value={employee.terminalIdentifier} setValue={(value) => {handleSetEmployee(index, 'terminalIdentifier', value)}} itemNames={terminals.map(t => t.name)} itemValues={terminals.map(t => t.uid)} validation={Validation.nonEmptyString}/>
                </td>
                <td>
                    <TablePicker value={employee.employeeType} setValue={(value) => {handleSetEmployee(index, 'employeeType', value)}} itemNames={Object.values(employeeTypeDictionary)} itemValues={Object.keys(employeeTypeDictionary)} validation={Validation.nonEmptyString}/>
                </td>
                <td>
                    <TablePicker value={employee.title} setValue={(value) => {handleSetEmployee(index, 'title', value)}} itemNames={['Driver', 'BC']} itemValues={['Driver', 'BC']} validation={Validation.nonEmptyString}/>
                </td>
                <td>
                    <TableField value={employee.payRate} setValue={(value) => {handleSetEmployee(index, 'payRate', value)}} type='number' validation={Validation.greaterThanZero}/>
                </td>
                <td>
                    <TableField value={employee.hourlyWage} setValue={(value) => {handleSetEmployee(index, 'hourlyWage', value)}} type='number' validation={Validation.greaterThanZero}/>
                </td>
            </tr>
        )
    })

    const isValid = employees.reduce((acc, el) => {if (el.isValid){return acc} return false}, true);

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Upload Onboarding CSV</Modal.Title>
            </Modal.Header>
            <Modal.Header style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                <p style={{marginBottom: 0}}>1. Download the <a download='OnboardingTemplateLinehaul.xlsx' href={onboardingTemplate}>onboarding template</a></p>
                <p style={{marginBottom: 0}}>2. Fill it out with the employees you need to hire</p>
                <p>3. Upload onboarding CSV</p>
                <Form.Control type='file' ref={fileRef} accept=".csv" onChange={handleFileChange}/>
                { fileError && 
                    <span style={{color: 'red'}}>{fileError}</span>
                }
            </Modal.Header>
            { employeeRows.length > 0 && 
                <>
                    <Modal.Body style={{width: '100%', overflowX: 'auto'}}>
                        <div style={{width: 3500}}>
                            <QuickTable 
                                responsive={false}
                                headers={['First Name', 'Middle Name', 'Last Name', 'Email Address', 'Phone Number', 'Date of Birth', 'SSN', 'Street Address 1', 'Street Address 2', 'State', 'City', 'Postal Code', 'Pay Type', 'Terminal', 'Employee Type', 'Title', 'Pay Rate', 'Overtime Rate']}
                                rows={employeeRows}
                                widths={[null, null, null, null, null, 110, null, null, null, null, null, 120, 170, null, 170, 180, 120, 120]}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton label='Submit' disabled={!isValid || employees.length == 0} isLoading={isSubmitting} onClick={handleSubmit}>Submit</CustomButton>
                    </Modal.Footer>
                </>
            }
        </>
    )
}  

function TableField({value, setValue, type, validation, max, min}) {


    const handleOnChange = (event) => {
        let newValue = event.target.value;
        if (max !== undefined && type === 'text' && newValue.length > max) {
            newValue = newValue.slice(0, max);
        } else if (max !== undefined && type === 'number' && validateDecimal(newValue) > max) {
            newValue = max.toString();
        } else if (min !== undefined && type === 'number' && validateDecimal(event.target.value) < min) {
            newValue = min.toString();
        }
        setValue(newValue);
    }

    return (
        <Form.Control 
            value={value} 
            onChange={handleOnChange} 
            type={type} 
            isInvalid={validation && validation(value)} 
            isValid={validation && !validation(value)}
        />
    )
}

function TablePicker({value, setValue, itemNames, itemValues, validation}) {
    const [show, setShow] = useState(false);

    const getNameForValue = (v) => {
        const index = itemValues.indexOf(v);
        if (index >= 0) {
            return itemNames[index];
        } else {
            return '';
        }
    }
    
    const getActiveName = () => {
        for (let i = 0; i < itemValues.length; i++) {
            if (itemValues[i] == value) {
                return itemNames[i];
            }
        }
        return '';
    }
    const [search, setSearch] = useState(getActiveName());
    
    const handleSearch = (newValue) => {
        const index = itemNames.indexOf(newValue);
        if (index >= 0) {
            setValue(itemValues[index]);
        } else {
            setValue('');
        }
        setSearch(newValue);
        if (!show) {
            setShow(true);
        }
    }

    const filteredItems = itemValues.filter((e, i) => {
        return itemNames[i].toLowerCase().includes(search.toLowerCase());
    })
    
    const dropdownItems = filteredItems.map((key, index) => {
        return (
            <Dropdown.Item key={key} active={value == key || (!value && search && index == 0)} onClick={() => {setValue(key); setSearch(getNameForValue(key)); setShow(false)}}>{getNameForValue(key)}</Dropdown.Item>
        )
    });

    return (
        <Dropdown as={InputGroup} show={show} onBlur={(event) => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
                setShow(false);
            }
        }}>
            <Form.Control 
                value={search}
                onFocus={() => {setShow(true)}}
                onChange={(event) => {handleSearch(event.target.value)}}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && search) {
                        const match = filteredItems.find(v => getNameForValue(v).toLowerCase().includes(search.toLowerCase()));
                        if (match) {
                            setValue(match);
                            setSearch(getNameForValue(match));
                            setShow(false);
                        }
                    }
                }}
                style={{backgroundColor: 'white'}} 
                isInvalid={validation && validation(value)} 
                isValid={validation && !validation(value)}
            />
            <Dropdown.Toggle variant={'outline-primary'} onClick={() => {setShow(!show)}}/>
            <Dropdown.Menu>
                {dropdownItems}
            </Dropdown.Menu>
        </Dropdown>
    )
}