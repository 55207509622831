/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import StateObject from "../../../../state/StateObject.tsx";
import Decoder from "../../../../decoding.js";
import Vehicle from "../../MyCompany/Models/Vehicle.js";
import { getFakeUID } from "../../../../tools.js";

export default class Route extends StateObject{
    uid;
    user;
    vehicle;
    name;
    date;
    notes;
    stops;

    constructor(
        uid,
        user,
        vehicle,
        name,
        date,
        notes,
        stops,
    ){
        super();
        this.uid = uid;
        this.user = user;
        this.vehicle = vehicle;
        this.name = name;
        this.date = date;
        this.notes = notes;
        this.stops = stops;
        this.initChildren();
    }

    static initDefault(selectedDate){
        return new Route(
            -1,
            null,
            null,
            '',
            selectedDate ?? '0001-01-01',
            '',
            []
        )
    }

    static decode(json){
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const user = json.user;
        const vehicle = Vehicle.decode(json.vehicle);
        const name = decoder.decode('name', Decoder.String, {defaultValue: '', warn: false});
        const date = decoder.decode('date', Decoder.Date);
        const notes = decoder.decode('notes', Decoder.String, {defaultValue: '', warn: false});
        const stops = json.stops.map(s => ({...s, id: `stop${getFakeUID()}`}));

        if(decoder.checkForErrors()){
            return new Route(uid, user, vehicle, name, date, notes, stops);
        } else{
            return Route.initDefault();
        }
    }

    static decodeFromTemplateRoute(json, startDate){
        const decoder = new Decoder(json);
        const uid = decoder.decode('uid', Decoder.Uid);
        const user = json.user;
        const vehicle = Vehicle.decode(json.vehicle);
        const name = decoder.decode('name', Decoder.String, {defaultValue: '', warn: false});
        const dayIndex = decoder.decode('dayIndex', Decoder.Integer);
        const date = moment(startDate).add(dayIndex, 'days').format('YYYY-MM-DD');
        const stops = json.stops.map(s => ({...s, id: `stop${getFakeUID()}`}));

        if(decoder.checkForErrors()){
            return new Route(uid, user, vehicle, name, date, '', stops);
        } else{
            return Route.initDefault();
        }
    }

    duplicate(){
        return new Route(this.uid, this.user, this.vehicle, this.name, this.date, this.notes, this.stops);
    }
    
    encode(){
        return {
            uid: this.uid,
            companyUserIdentifier: this.user.companyUserIdentifier,
            vehicleIdentifier: this.vehicle.uid,
            name: this.name,
            date: this.date,
            notes: this.notes,
            stops: this.stops
        }
    }
}