/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { Modal } from 'react-bootstrap';
import AcknowledgementModal from './views/AcknowledgementModal';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotificationModal from './views/NotificationModal';
import { ToastContainer } from 'react-toastify';
import ApiManager, { ApiRequest } from './ApiManager.tsx';
import Cookies from 'universal-cookie';
import LoadingWrapper from './components/LoadingWrapper.js';
import ApiTimeoutModal from './views/ApiTimeoutModal.js';
import 'react-toastify/dist/ReactToastify.css';
import getRoutes from './routes.js';

export default function App() {
    const contentRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [auth, setAuth] = useState(null);
    const [showTimeout, setShowTimeout] = useState(false);
    const [showOffCanvas, setShowOffCanvas] = useState(true);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [initializedCobrowse, setInitializedCobrowse] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [permissions, setPermissions] = useState([]);

    useEffect(() => {
        ApiManager.initGlobalInstance(setAuth, setShowTimeout, handleCheckAuthentication);
        handleCheckAuthentication();
    }, [])

    const handleCheckAuthentication = () => {
        new ApiRequest('authentication', 'checkAuthentication', setIsLoading, (response) => {
            const cookies = new Cookies();
            cookies.set('auth', response.auth);
            cookies.set('userData', response.userData);
            setPermissions(response.permissions);
            cookies.set('permissions', response.permissions);
            if (response.auth.companyUserIdentifier) {
                cookies.set('restrictWhoSeesPay', response.restrictWhoSeesPay);
                cookies.set('companyName', response.companies.find(c => c.uid == response.userData.companyIdentifier)?.name ?? '');
                setCompanies(response.companies);
                if (response.documents?.length > 0) {
                    setShowDocumentModal(true);
                    setDocuments(response.documents);
                }
            }
            if (!initializedCobrowse) {
                initializeCobrowse(response.userData, response.auth);
                setInitializedCobrowse(true);
            }
            setAuth(response.auth);
        }).withNoAlertOnSuccess().send();
    }


    function initializeCobrowse(userData, auth) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = `(function(w,t,c,p,s,e){p=new Promise(function(r){w[c]={client:function(){if(!s){
          s=document.createElement(t);s.src='https://js.cobrowse.io/CobrowseIO.js';s.async=1;s.crossOrigin='anonymous';
          e=document.getElementsByTagName(t)[0];e.parentNode.insertBefore(s,e);s.onload=function()
          {r(w[c]);};}return p;}};});})(window,'script','CobrowseIO');
    
          CobrowseIO.license = "h-pVfTMTlbqNvQ";
          CobrowseIO.customData = {
            user_id: "${auth.companyUserIdentifier ?? auth.adminIdentifier ?? 'UNKNOWN'}",
            user_name: "${userData?.firstName ?? 'TTA'} ${userData?.lastName ?? 'Admin'}",
            device_name: "Linehaul - ${userData?.firstName ?? 'TTA'} ${userData?.lastName ?? 'Admin'}}"
          };
          CobrowseIO.client().then(function(){
              CobrowseIO.start();
          });`;
        document.body.appendChild(s);
    }

    const routes = getRoutes(auth, permissions, handleCheckAuthentication);

    const routeElements = routes.map((route) => {
        return (
            <Route key={route.name} path={route.path} component={route.component}/>
        )
    });

    return (
        <div className='App'>
            <LoadingWrapper isLoading={isLoading}>
                <BrowserRouter>
                    {(auth?.companyUserIdentifier || auth?.adminIdentifier) &&
                        <NavigationBar routes={routes} auth={auth} companies={companies} handleCheckAuthentication={handleCheckAuthentication}/>
                    }

                    <Switch>{routeElements}</Switch>
                    {/* <Router auth={auth} permissions={permissions} handleCheckAuthentication={handleCheckAuthentication}/> */}
                </BrowserRouter>
            </LoadingWrapper>
            <Modal show={showDocumentModal} onHide={() => {setShowDocumentModal(false)}} fullscreen backdrop='static'>
                <AcknowledgementModal documents={documents} hideModal={() => {setShowDocumentModal(false)}}/>
            </Modal>
            <ToastContainer toastStyle={{fontSize: 14}} autoClose={2500}/>
        </div>
    )
}