/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

export default function DropdownItemWithCheck({style, active, onClick, label}){

    const handleOnClick = (e) => {
        e.stopPropagation();
        if(onClick){
            onClick()
        }
    }

    return (
        <DropdownItem style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8, ...style}} active={active} onClick={handleOnClick}>
             <p style={{margin: 0}}>{label}</p>
            {active && <FontAwesomeIcon color='white' icon={faSquareCheck}/>}
        </DropdownItem>
    )

}