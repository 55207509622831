/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState } from "react"
import { ApiRequest } from "../../../../ApiManager.tsx";
import { Button, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faTrash} from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../../components/CustomButton.js";
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation.js";
import CustomTextArea from "../../../../components/CustomStateControls/CustomTextArea.js";
import CustomToolTip from "../../../../components/CustomToolTip.js";
import './RouteCard.scss'
import CustomControl from "../../../../components/CustomControl.js";
import { DriverDroppable, StopDroppable, VehicleDroppable } from "./DroppableComponents.js";
import SwitchControl from "../../../../components/SwitchControl.js";
import moment from "moment";

export default function RouteCard({route, handleSetRoute, handleLockAllRouteInTimes, handleLockAllRouteOutTimes, handleSetRouteStop, templateMode, selectedRoute, setRoute, selectedTemplate, handleRoutesCrud, conflicts, dragPlaceholderIndex, routesForDay,
users, terminals, vehicles}){
    const selectedDateIsAfterCurrentDate = moment(route.date).isSameOrAfter(moment().startOf('day'))
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [sendNotification, setSendNotification] = useState(Boolean(selectedRoute) && selectedDateIsAfterCurrentDate)
    const [modalSwitch, setModalSwitch] = useState('');

    function handleCreateRoute(){
        new ApiRequest('scheduling', 'createRoute', setIsSubmitting, (response) => {
            route.uid = response.uid;
            handleRoutesCrud('create', route);
            hideModal();
        }).withData({route: route.encode(), sendNotification: sendNotification}).send()
    }
    
    function handleUpdateRoute(){
        new ApiRequest('scheduling', 'updateRoute', setIsSubmitting, () => {
            handleRoutesCrud('update', route);
            hideModal();
        }).withData({route: route.encode(), sendNotification: sendNotification}).send()
    }

    function handleDeleteRoute(){
        new ApiRequest('scheduling', 'deleteRoute', setIsDeleting, () => {
            handleRoutesCrud('delete', route.uid);
        }).withData({uid: route.uid, sendNotification: sendNotification}).send();
    }

    function handleCreateTemplateRoute(){
        new ApiRequest('scheduling', 'createTemplateRoute', setIsSubmitting, (response) => {
            route.uid = response.uid;
            handleRoutesCrud('create', route, true);
            hideModal();
        }).withData({uid: selectedTemplate.uid, route: route.encode()}).send()
    }
    
    function handleUpdateTemplateRoute(){
        new ApiRequest('scheduling', 'updateTemplateRoute', setIsSubmitting, () => {
            handleRoutesCrud('update', route, true);
            hideModal();
        }).withData({route: route.encode()}).send()
    }

    function handleDeleteTemplateRoute(){
        new ApiRequest('scheduling', 'deleteTemplateRoute', setIsDeleting, () => {
            handleRoutesCrud('delete', route.uid, true);
        }).withUid(route.uid).send();
    }

    function hideModal(){
        setModalSwitch('');
    }

    const hasSevereConflicts = conflicts.stops.some(
        (el) => el.length && el.some((c) => c.severity > 2)
      );
    const isRouteValid = route.vehicle && route.user;
    const hasAtLeast2Stops = route.stops.length > 1;
    const canBeSaved = !hasSevereConflicts && isRouteValid && hasAtLeast2Stops;
    const canBeSavedString = canBeSaved ? '': ' (Resolve Red Conflicts)';

    return (
        <div style={{marginBottom: 24}}>
            <div style={{overflowX: 'auto', minHeight: 492}}>
                <Table bordered style={{ backgroundColor: 'white', minWidth: 700}}>
                    <thead>
                        <tr>
                            <th colSpan="100%">
                                <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                                    <CustomControl title="Route Name" value={route.name} setValue={(value) => handleSetRoute('name', value)} max={30} />
                                    <Button variant="danger" onClick={() => setModalSwitch('delete')}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </Button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <DriverDroppable conflicts={conflicts} route={route} routesForDay={routesForDay} templateMode={templateMode} handleSetRoute={handleSetRoute} terminals={terminals} users={users} />
                        <VehicleDroppable conflicts={conflicts} route={route} routesForDay={routesForDay} handleSetRoute={handleSetRoute} terminals={terminals} vehicles={vehicles} />
                        <tr style={{ backgroundColor: 'gold' }}>
                            <td style={{ textAlign: 'center' }}>
                                <span><b>Move</b></span>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                                <span><b>Stop</b></span>
                            </td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <span><b>Time In</b></span>
                            </td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <CustomToolTip icon={faLock} onClick={handleLockAllRouteOutTimes} text="Checking this box will prevent the autofill feature from overwriting this time in, if there is one." />
                            </td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <span><b>Time Out</b></span>
                            </td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <CustomToolTip onClick={handleLockAllRouteInTimes} icon={faLock} text="Checking this box will prevent the autofill feature from overwriting this time out, if there is one." />
                            </td>
                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                <FontAwesomeIcon icon={faTrash} />
                            </td>
                        </tr>
                        <StopDroppable conflicts={conflicts} route={route} setRoute={setRoute} dragPlaceholderIndex={dragPlaceholderIndex} handleSetRouteStop={handleSetRouteStop} />
                    </tbody>
                </Table>
            </div>

            {!templateMode && (
                <div style={{ padding: '28px 0 28px 0', backgroundColor: 'rgb(240, 240, 240)' }}>
                    <CustomTextArea placeholder={'Notes'} value={route.notes} setValue={(value) => handleSetRoute('notes', value)} rows={3} max={200} />
                </div>
            )}

            {selectedDateIsAfterCurrentDate && <SwitchControl title="Send employee notification?" style={{marginBottom: 12}} value={sendNotification} setValue={setSendNotification}/>}

            <CustomButton isLoading={isSubmitting} style={{ width: '100%' }} disabled={!canBeSaved} onClick={() => {
                if (templateMode) {
                    selectedRoute ? handleUpdateTemplateRoute() : handleCreateTemplateRoute();
                } else {
                    selectedRoute ? handleUpdateRoute() : handleCreateRoute();
                }
            }}>
                Save{route.stops.length < 2 ? ' (Minimum of 2 Stops)' : canBeSavedString}
            </CustomButton>

            <Modal centered show={modalSwitch === 'delete'} onHide={hideModal}>
                <AlertConfirmation isLoading={isDeleting} variant="outline-danger" title="Delete Route" message="Are you sure you want to delete this route?" optionalJsx={selectedDateIsAfterCurrentDate ? <SwitchControl title="Send employee notification?" style={{ marginBottom: 12, marginTop: 12 }} value={sendNotification} setValue={setSendNotification} /> : <></>} value={sendNotification} setValue={setSendNotification} buttonLabel="Delete" callBack={templateMode ? handleDeleteTemplateRoute : handleDeleteRoute} />
            </Modal>
        </div>
    )
}
