/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { ApiRequest } from '../../../../ApiManager.tsx';
import { encodeAvailability, ptoAccrualTypeDictionary } from '../../../../tools.js';
import { Alert, CloseButton, Col, Row } from 'react-bootstrap';
import LoadingWrapper from '../../../../components/LoadingWrapper.js';
import AddressAutoFill from '../../../../components/CustomStateControls/AddressAutoFill.js';
import RadioControl from '../../../../components/RadioControl.js';
import AvailabilityControl from '../../../../components/AvailabilityControl.js';
import BwcCodeControl from '../../../../components/BwcCodeControl.js';
import ChildSupportControl from '../../../../components/ChildSupportControl.js';
import AutomaticDeductionControl from '../../../../components/AutomaticDeductionControl.js';
import AutomaticReimbursementControl from '../../../../components/AutomaticReimbursementControl.js';
import HRHirePermissions from './HRHirePermissions.js';
import CustomButton from '../../../../components/CustomButton.js';
import StateObject from '../../../../state/StateObject.tsx';
import ControlStateProps from '../../../../state/ControlStateProps.tsx';
import { Validation } from '../../../../validation.tsx';
import DocumentRequestModal from '../HRDocuments/DocumentRequestModal.js';
import { useStateObjectWithValidation } from '../../../../hooks.tsx';
import Address from '../../../../models/Address.js';
import CustomControl from '../../../../components/CustomControl.js';

export default function HRHire({terminals, hideModal, selectedUser}) {
    const [personalInformation, setPersonalInformation, setPersonalInformationKey, personalInformationVMs, personalInformationIsValid] = useStateObjectWithValidation({
        firstName: selectedUser?.firstName ?? '',
        middleName: selectedUser?.middleName ?? '',
        lastName: selectedUser?.lastName ?? '',
        email: selectedUser?.email ?? '',
        phoneNumber: selectedUser?.phoneNumber ?? '',
        dateOfBirth: selectedUser?.dateOfBirth ?? '',
        ssn: '',
    },
    {
        firstName: Validation.nonEmptyString,
        middleName: Validation.none,
        lastName: Validation.nonEmptyString,
        email: Validation.email,
        phoneNumber: Validation.phoneNumber,
        dateOfBirth: Validation.date,
        ssn: Validation.ssn,
    });
    const [employmentDetails, setEmploymentDetails, setEmploymentDetailsKey, employmentDetailsVMs, employmentDetailsIsValid] = useStateObjectWithValidation({
        employeeType: '',
        title: '',
        terminalIdentifier: null,
        availability:[false, false, false, false, false, false, false],
        payType: '',
        payRate: '',
        hourlyWage: '',
        dateEffective: '',
        ptoAccrual: '',
        ptoAccrualType: undefined,
        bwcCode: '7231',
        employeeId: '',
        medical: '',
        dental: '',
        vision: '',
        childSupport: [],
        automaticDeductions: [],
        automaticReimbursements: [],
    }, 
    {
        employeeType: Validation.nonEmptyString,
        title: Validation.nonEmptyString,
        terminalIdentifier: Validation.notUndefined,
        availability: () => [],
        payType: Validation.nonEmptyString,
        payRate: Validation.greaterThanZero,
        hourlyWage: Validation.greaterThanZero,
        dateEffective: Validation.date,
        ptoAccrual: Validation.nonEmptyString,
        ptoAccrualType: Validation.notUndefined,
        bwcCode: Validation.nonEmptyString,
        employeeId: Validation.nonEmptyString,
        medical: Validation.nonEmptyString,
        dental: Validation.nonEmptyString,
        vision: Validation.nonEmptyString,
        childSupport: (value) => validationForArrays(value, true),
        automaticDeductions: (value) => validationForArrays(value, false),
        automaticReimbursements: (value) => validationForArrays(value, false),
    });
    const [formObject, _setformObject] = useState(new StateObject({address: selectedUser?.address ? Address.decodeNonStrict(selectedUser.address) :undefined}))
    const [permissions, setPermissions] = useState([]);
    const [permissionsTemplate, setPermissionsTemplate] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showCloseWarning, setShowCloseWarning] = useState(false);
    const [sendOnboardingDocuments, setSendOnboardingDocuments] = useState(true);
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [companyUserIdentifier, setCompanyUserIdentifier] = useState(selectedUser ? selectedUser.companyUserIdentifier : null);

    useEffect(()=>{
        loadData();
    }, [])

    async function loadData() {
        new ApiRequest('hr', 'getHireResources', setIsLoading, (response) => {
            setPermissions([]);
            setPermissionsTemplate(response.filteredTemplate);
        }).withNoAlertOnSuccess().send();
    }

    function handleSubmit(){
        const hire = {
            ...personalInformation,
            ...employmentDetails,
            availability: encodeAvailability(employmentDetails.availability),
            automaticReimbursements: employmentDetails.automaticReimbursements,
            automaticDeductions: employmentDetails.automaticDeductions,
            childSupport: employmentDetails.childSupport,
            permissions: permissions,
            address: formObject.address.encode(),
            notes: 'Auto-generated from new hire.'
        }

        if (selectedUser) {
            hire['userIdentifier'] = selectedUser.userIdentifier;
            hire['companyUserIdentifier'] = selectedUser.companyUserIdentifier;
            new ApiRequest('hr', 'rehire', setIsSubmitting, (response) => {
                if (sendOnboardingDocuments) {
                    setShowOnboardingModal(true); 
                } else {
                    window.location.reload();
                }
                
            }).withData({hire: hire}).send();
        } else{
            new ApiRequest('hr', 'hire', setIsSubmitting, (response) => {
                setCompanyUserIdentifier(response.companyUserIdentifier);
                if (sendOnboardingDocuments) {
                    setShowOnboardingModal(true); 
                } else {
                    window.location.reload();
                }
            }).withData({hire: hire}).send()
        }
    }

    function handlePermissionChange(permissionName) {
        if(permissions.includes(permissionName)){
            setPermissions(permissions.filter(p=> p !== permissionName));
        }else{
            setPermissions([...permissions, permissionName])
        }
    }

    function handleUseTemplate(template) {
        const newPermissions = [];
        permissionsTemplate.forEach(permission => {if(permission[template]){newPermissions.push(permission.permissionName)}});
        setPermissions(newPermissions);
    }

    return (
        <>
            <Modal.Header>
                <Modal.Title>{selectedUser ? 'Rehire Employee' : 'Hire New Employee'}</Modal.Title>
                <CloseButton onClick={() => setShowCloseWarning(true)}/>
            </Modal.Header>
            <Modal.Body style={{display: 'flex', flexDirection: 'column', gap: 12, backgroundColor: 'lightgray'}}>
                <LoadingWrapper isLoading={isLoading}>
                    <div style={{borderRadius: 6, padding: 12, backgroundColor: 'white'}}>
                        <h5 style={{textAlign:'center', marginBottom: 16}}>Personal Information</h5>
                        <Row>
                            <CustomControl breakpoints={{lg: 4}} title='First Name' maxLength={30} type='text' max={30} validate validationMessage={personalInformationVMs.firstName} value={personalInformation.firstName} setValue={(value) => setPersonalInformationKey('firstName', value)}/>
                            <CustomControl breakpoints={{lg: 4}} title='Middle Name' maxLength={30} type='text' max={30} validate validationMessage={personalInformationVMs.middleName} value={personalInformation.middleName} setValue={(value) => setPersonalInformationKey('middleName', value)}/>
                            <CustomControl breakpoints={{lg: 4}} title='Last Name' maxLength={30} type='text' max={30} validate validationMessage={personalInformationVMs.lastName} value={personalInformation.lastName} setValue={(value) => setPersonalInformationKey('lastName', value)}/>
                            <CustomControl breakpoints={{lg: 6}} title='Email Address' maxLength={50} max={50} type='text' validate validationMessage={personalInformationVMs.email} value={personalInformation.email} setValue={(value) => setPersonalInformationKey('email', value)}/>
                            <CustomControl breakpoints={{lg: 6}} title='Phone Number' maxLength={10} max={10} type='text' validate validationMessage={personalInformationVMs.phoneNumber} value={personalInformation.phoneNumber} setValue={(value) => setPersonalInformationKey('phoneNumber', value)}/>
                            <CustomControl breakpoints={{lg: 6}} title='Date of Birth' max={moment().format('YYYY-MM-DD')} type='date' validate validationMessage={personalInformationVMs.dateOfBirth} value={personalInformation.dateOfBirth} setValue={(value) => setPersonalInformationKey('dateOfBirth', value)}/>
                            <CustomControl breakpoints={{lg: 6}} title='Social Security #' maxLength={9} lg={3} max={9} type='text' validate validationMessage={personalInformationVMs.ssn} value={personalInformation.ssn} setValue={(value) => setPersonalInformationKey('ssn', value)}/>
                            <Col xxl={12}>
                                <AddressAutoFill label='Address' stateProps={new ControlStateProps('address', formObject, Validation.notUndefined)}/>
                            </Col>
                        </Row>
                    </div>
                    <div style={{borderRadius: 6, padding: 12, backgroundColor: 'white'}}>
                        <h5 style={{textAlign:'center', marginBottom: 16}}>Employment Information</h5>
                            <Row>
                                <RadioControl labelStyle={{width: 135, fontSize: 12}} breakpoints={{lg: 12}} inline title='Employee Type' validate validationMessage={employmentDetailsVMs.employeeType} selection={employmentDetails.employeeType} setSelection={(value) => setEmploymentDetailsKey('employeeType', value)} optionNames={['Full-Time', 'Part-Time']} optionValues={[0, 1]}/>
                                <RadioControl labelStyle={{width: 135, fontSize: 12}} breakpoints={{lg: 12}} inline title='Title' validate validationMessage={employmentDetailsVMs.title} selection={employmentDetails.title} setSelection={(value) => setEmploymentDetailsKey('title', value)} optionNames={['Driver', 'BC']} optionValues={['Driver', 'BC']}/>
                                <RadioControl labelStyle={{width: 135, fontSize: 12}} breakpoints={{lg: 12}} inline title='Terminal' validate validationMessage={employmentDetailsVMs.terminalIdentifier}  selection={employmentDetails.terminalIdentifier}  setSelection={(value) => setEmploymentDetailsKey('terminalIdentifier', value)}  optionNames={terminals.map(t => t.name)}  optionValues={terminals.map(t => t.uid)}/>
                                <Col lg={12} style={{marginBottom: 8}}>
                                    <RadioControl labelStyle={{width: 135, fontSize: 12}} inline title='Pay Type' validate validationMessage={employmentDetailsVMs.payType} selection={employmentDetails.payType} setSelection={(value) => setEmploymentDetailsKey('payType', value)} optionNames={['Per Year', 'Per Day', 'Per Hour', 'Per Mile']} optionValues={['py', 'pd', 'ph', 'pm']}/>
                                    {employmentDetails.payType !== 'py' &&  employmentDetails.title === 'BC' && <Alert variant='warning' style={{padding:5, fontSize:12, margin:5}}>BC's are recommended to be paid Per Year </Alert> }
                                </Col>
                                <BwcCodeControl labelStyle={{width: 135, fontSize: 12}} lg={12} inline validate validationMessage={employmentDetailsVMs.bwcCode} value={employmentDetails.bwcCode} setValue={(value) => setEmploymentDetailsKey('bwcCode', value)}/>
                                <Col lg={12} style={{marginBottom: 8}}>
                                    <CustomControl title='PTO Accrual (In Hours)' max={99.999} type='number' validate validationMessage={employmentDetailsVMs.ptoAccrual} value={employmentDetails.ptoAccrual} setValue={(value) => setEmploymentDetailsKey('ptoAccrual', value)}/>
                                    <div style={{marginTop: 5}}>
                                        <RadioControl labelStyle={{width: 135, fontSize: 12}} lg={12} inline title='Accrual Frequency' validate validationMessage={employmentDetailsVMs.ptoAccrualType} selection={employmentDetails.ptoAccrualType} setSelection={(value) => setEmploymentDetailsKey('ptoAccrualType', value)} optionNames={Object.values(ptoAccrualTypeDictionary)} optionValues={Object.keys(ptoAccrualTypeDictionary)}/>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <CustomControl title='Pay Rate' max={999999.99} type='number' validate validationMessage={employmentDetailsVMs.payRate} value={employmentDetails.payRate} setValue={(value) => setEmploymentDetailsKey('payRate', value)}/>
                                    {employmentDetails.payRate < 35568 && employmentDetails.payType === 'py' &&  <Alert variant='warning' style={{padding:5, fontSize:12}}>Per Year Employees will receive overtime if their pay rate is equal to or below $35568.00 </Alert> }
                                </Col>
                                <CustomControl breakpoints={{lg: 6}} title='Overtime Rate' max={999.99} type='number' validate validationMessage={employmentDetailsVMs.hourlyWage} value={employmentDetails.hourlyWage} setValue={(value) => setEmploymentDetailsKey('hourlyWage', value)}/>
                                <CustomControl breakpoints={{lg: 6}} title='Start Date' type='date' validate validationMessage={employmentDetailsVMs.dateEffective} value={employmentDetails.dateEffective} setValue={(value) => setEmploymentDetailsKey('dateEffective', value)}/>
                                <CustomControl breakpoints={{lg: 6}} title='Employee Id' type='text' max={30} validate validationMessage={employmentDetailsVMs.employeeId} value={employmentDetails.employeeId} setValue={(value) => setEmploymentDetailsKey('employeeId', value)}/>
                                <AvailabilityControl lg={12} inline title='Expected Availability' value={employmentDetails.availability} setValue={(value) => setEmploymentDetailsKey('availability', value)}/>
                                <CustomControl breakpoints={{md: 4}} title='Medical Insurance' max={9999.99} type='number' validate validationMessage={employmentDetailsVMs.medical} value={employmentDetails.medical} setValue={(value) => setEmploymentDetailsKey('medical', value)}/>
                                <CustomControl breakpoints={{md: 4}} title='Dental Insurance' max={9999.99} type='number' validate validationMessage={employmentDetailsVMs.dental} value={employmentDetails.dental} setValue={(value) => setEmploymentDetailsKey('dental', value)}/>
                                <CustomControl breakpoints={{md: 4}} title='Vision Insurance' max={9999.99} type='number' validate validationMessage={employmentDetailsVMs.vision} value={employmentDetails.vision} setValue={(value) => setEmploymentDetailsKey('vision', value)}/>   
                                <ChildSupportControl value={employmentDetails.childSupport} validationMessages={employmentDetailsVMs.childSupport} setValue={(value) => setEmploymentDetailsKey('childSupport', value)} breakpoints={{xxl: 12}}/>
                                <AutomaticDeductionControl value={employmentDetails.automaticDeductions} validationMessages={employmentDetailsVMs.automaticDeductions} setValue={(value) => setEmploymentDetailsKey('automaticDeductions', value)} breakpoints={{xxl: 12}}/>
                                <AutomaticReimbursementControl value={employmentDetails.automaticReimbursements} validationMessages={employmentDetailsVMs.automaticReimbursements} setValue={(value) => setEmploymentDetailsKey('automaticReimbursements', value)} breakpoints={{xxl: 12}}/>
                            </Row>
                    </div>
                    <div style={{borderRadius: 6, padding: 12, backgroundColor: 'white'}}>
                        <h5 style={{textAlign:'center'}}>Permissions</h5>
                        <HRHirePermissions 
                            handlePermissionChange={handlePermissionChange} 
                            handleUseTemplate={handleUseTemplate} 
                            permissions={permissions} 
                            permissionsTemplate={permissionsTemplate}
                        />
                    </div>
                </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
                <Form.Check type='switch' label='Send Employee Onboarding Documents?' checked={sendOnboardingDocuments} onChange={() => setSendOnboardingDocuments(!sendOnboardingDocuments)}/>
                <CustomButton label='Submit' isLoading={isSubmitting} disabled={!employmentDetailsIsValid || !personalInformationIsValid} stateObject={formObject} onClick={handleSubmit}>Submit</CustomButton>
            </Modal.Footer>  
            <Modal show={showCloseWarning} onHide={() => setShowCloseWarning(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Discard Unsubmitted Progress?</Modal.Title>
                </Modal.Header>
                <Modal.Body>You will lose all progress if you close out of this modal before submitting.</Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-primary'} onClick={() => setShowCloseWarning(false)}>Cancel</Button>
                    <Button variant={'outline-primary'} onClick={() => {setShowCloseWarning(false); hideModal()}}>Discard Progress</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showOnboardingModal} onHide={() => window.location.reload()} size='xl' fullscreen={'xl-down'}>
                <DocumentRequestModal defaultAllDocumentsOn companyUserIdentifier={companyUserIdentifier} hideModal={() => window.location.reload()}/>
            </Modal>
        </>
    )
}

export function validationForArrays(array, isChildSupport){
    if(!array){
        return;
    }
    if(isChildSupport){
        return array.map(cs => {if(!cs){return 'Amount is required'}});
    } else{
        return array.map(arr => {
            if(!arr.type && !arr.amount){
                return 'Both an amount and type is required';
            } else if (!arr.type){
                return 'Type is required'
            } else if (!arr.amount){
                return 'Amount is required';
            }
        })
    }
}