/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { Button, ListGroup, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCheck, faEllipsis, faLocationDot, faTruck, faUser, faX } from '@fortawesome/free-solid-svg-icons';
import KeyValueRow from '../../../components/KeyValueRow';
import { formatDateRange, formatTimeNew } from '../../../tools';
import CustomButton from '../../../components/CustomButton';
import AlertConfirmation from '../../../components/AlertModals/AlertConfirmation';
import { ApiRequest } from '../../../ApiManager.tsx';

export default function MyScheduleCalendar({routes, timeOff, calendarStartDate, startOfMonth, handleRemoveRequestOff}) {
    const [selectedTimeOff, setSelectedTimeOff] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    function handleDeleteTimeOff(){
        new ApiRequest('mySchedule', 'deleteTimeOff', setIsDeleting, () => {
            handleRemoveRequestOff(selectedTimeOff.uid);
            setShowModal(false);
            setShowDeleteModal(false);
        }).withUid(selectedTimeOff.uid).send();
    }

    const weekRows = [0, 1, 2, 3, 4, 5].map((weekIndex) => {
        const dayElements = [0, 1, 2, 3, 4, 5, 6].map((dayIndex) => {
            const date = moment(calendarStartDate).add(weekIndex, 'weeks').add(dayIndex, 'days');

            const routeOnDay = routes.find(r => r.date == date.format('YYYY-MM-DD'));
            const timeOffOnDay = timeOff.find(t => date.format('YYYY-MM-DD') >= t.startDate && date.format('YYYY-MM-DD') <= t.endDate);

            return (
                <td key={dayIndex} style={{position: 'relative'}}>
                    <div className='desktop-flex' style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: '100%', flexDirection: 'column', justifyContent: 'flex-end', padding: 3, gap: 4}}>
                        { routeOnDay && 
                            <RouteItem route={routeOnDay}/>
                        }
                        { timeOffOnDay && 
                            <TimeOffItem timeOff={timeOffOnDay} setShowModal={setShowModal} setSelectedTimeOff={setSelectedTimeOff}/>
                        }
                    </div>
                    <div className='mobile-flex' style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: '100%', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', padding: 3, gap: 4}}>
                        { routeOnDay && 
                            <OverlayTrigger overlay={<RouteItemOverlay route={routeOnDay}/>}>
                                <div style={{width: 18, height: 18, backgroundColor: 'var(--bs-primary)', borderRadius: '50%'}}/>
                            </OverlayTrigger>
                        }
                        { timeOffOnDay && 
                            <OverlayTrigger overlay={<TimeOffItemOverlay timeOff={timeOffOnDay}/>}>
                                <div style={{width: 18, height: 18, backgroundColor: timeOffOnDay.status == -1 ? 'red' : timeOffOnDay.status == 0 ? 'gold' : 'green', borderRadius: '50%'}}/>
                            </OverlayTrigger>
                        }
                    </div>
                    <p style={{position: 'absolute', right: 4, top: 4, fontSize: 14, lineHeight: 1}}>{date.format('D')}</p>
                    { date.format('MMM') !== moment(startOfMonth).format('MMM') && 
                        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'black', opacity: 0.075, pointerEvents: 'none'}}/>
                    }
                    { date.isSame(moment(), 'day') && 
                        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'var(--bs-primary)', opacity: 0.075, pointerEvents: 'none'}}/>
                    }
                </td>
            );
        })

        return (
            <tr key={weekIndex} style={{height: '16.66%'}}>
                {dayElements}
            </tr>
        );
    });

    const columnHeaders = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((str) => {
        return (<th key={str} style={{width: '14.286%', opacity: 0.66, fontSize: 14}}>{str}</th>);
    });

    const columnHeadersMobile = ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((str, index) => {
        return (<th key={index} style={{width: '14.286%', opacity: 0.66, fontSize: 14}}>{str}</th>);
    });

    return (
        <>
            <Table bordered size='sm' style={{width: '100%', flex: 1, margin: 0}}>
                <thead>
                    <tr className='desktop-table-row' style={{textAlign: 'center'}}>
                        {columnHeaders}
                    </tr>
                    <tr className='mobile-table-row' style={{textAlign: 'center'}}>
                        {columnHeadersMobile}
                    </tr>
                </thead>
                <tbody>
                    {weekRows}
                </tbody>
            </Table>
            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Viewing Timeoff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        <KeyValueRow isListItem title={'Start Date'} value={moment(selectedTimeOff?.startDate).format('MMM D, YYYY')}/>
                        <KeyValueRow isListItem title={'End Date'} value={moment(selectedTimeOff?.endDate).format('MMM D, YYYY')}/>
                        <KeyValueRow isListItem title={'Submission Date'} value={moment(selectedTimeOff?.submissionDate).format('MMM D, YYYY')}/>
                        <KeyValueRow isListItem title={'Request As PTO'} value={selectedTimeOff?.requestedAsPto ? 'Yes' : 'No'}/>
                        <KeyValueRow isListItem title={'Reason'} value={selectedTimeOff?.reason}/>
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={'outline-danger'} onClick={() => setShowDeleteModal(true)}>Delete Request</Button>
                </Modal.Footer>
            </Modal>
            <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <AlertConfirmation variant={'outline-danger'} isLoading={isDeleting} callBack={handleDeleteTimeOff} title='Delete Timeoff Request' message={'Are you sure you want to delete this timeoff request? This will also restore any pto hours used.'} buttonLabel={'Delete'}/>
            </Modal>
        </>
    );
}

function TimeOffItem({timeOff, setSelectedTimeOff, setShowModal}) {
    const icon = timeOff.status == -1 ? faX : timeOff.status == 0 ? faEllipsis : faCheck;
    const color = timeOff.status == -1 ? 'red' : timeOff.status == 0 ? 'gold' : 'green';

    const isClickable = timeOff.status !== -1 && moment(timeOff.endDate).isAfter(moment());

    return (
        <OverlayTrigger overlay={<TimeOffItemOverlay timeOff={timeOff}/>}>
            <div onClick={isClickable ? () => {setSelectedTimeOff(timeOff); setShowModal(true)} : undefined} style={{backgroundColor: 'white', cursor: isClickable ? 'pointer' : undefined, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap', padding: 6, border: `1px solid ${color}`, borderLeft: `6px solid ${color}`, borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                <span style={{overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold'}}>Time-Off Request</span>
                <FontAwesomeIcon icon={icon} style={{color: color, border: `1px solid ${color}`, width: 10, height: 10, borderRadius: '50%', padding: 2}}/>
            </div>
        </OverlayTrigger>
    )
}

const TimeOffItemOverlay = forwardRef(({timeOff, ...props}, ref) => {
    const label = timeOff.status == -1 ? 'Rejected' : timeOff.status == 0 ? 'Pending' : 'Accepted';

    return (
        <Popover ref={ref} {...props} style={{position: 'fixed', ...props.style}}>
            <Popover.Header>{`${label} Time-Off Request`}</Popover.Header>
            <Popover.Body style={{display: 'flex', flexDirection: 'column', gap: 4, padding: 6}}>
                <span style={{textAlign: 'center', fontWeight: 'bold'}}>{formatDateRange(timeOff.startDate, timeOff.endDate)}</span>
                <KeyValueRow title='Submitted' value={moment(timeOff.submissionDate).format('MMM D, YYYY')}/>
                <KeyValueRow title='Reason' value={timeOff.reason}/>
                { timeOff.notes && 
                    <KeyValueRow title='Notes from Employer' value={timeOff.notes}/>
                }
            </Popover.Body>
        </Popover>
    )
})

function RouteItem({route}) {
    return (
        <OverlayTrigger overlay={<RouteItemOverlay route={route}/>}>
            <div style={{backgroundColor: 'white', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, whiteSpace: 'nowrap', padding: 6, border: '1px solid var(--bs-primary)', borderLeft: '6px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                <span style={{overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 'bold'}}>Route</span>
                <FontAwesomeIcon icon={faTruck} style={{color: 'var(--bs-primary)'}}/>
            </div>
        </OverlayTrigger>
    )
}

const RouteItemOverlay = forwardRef(({route, ...props}, ref) => {
    const formatStopTime = (time, isNextDay) => {
        const formattedTime = moment(time, 'HH:mm:ss').format('hh:mm A');
        if (!isNextDay) {
            return `${formattedTime} (next day)`;
        }
        return formattedTime;
    };

    const stops = [...route.clients, ...route.terminals].sort((a, b) => a.startTime < b.startTime ? -1 : 1);
    const stopElements = stops.map((s) => {
        return (
            <div key={s.startTime} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 12, fontSize: 12, whiteSpace: 'normal'}}>
                <div style={{display: 'flex', alignItems: 'center', gap: 4}}>
                    <FontAwesomeIcon icon={s.clientIdentifier ? faLocationDot : faBuilding}/>
                    <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{s.name}</span>
                </div>
                <span>{`${formatStopTime(s.startTime, s.startTimeIsNextDay)} - ${formatStopTime(s.endTime, s.endTimeIsNextDay)}`}</span>
            </div>
        );
    });

    return (
        <Popover ref={ref} style={{position: 'fixed', width: 'auto', maxWidth: 'none', ...props.style}}>
            <Popover.Header>{`Route`}</Popover.Header>
            <Popover.Body style={{display: 'flex', flexDirection: 'column', gap: 4, padding: 6}}>
                <KeyValueRow title='Vehicle' value={route.vehicleName}/>
                <hr style={{margin: 0}}/>
                {stopElements}
            </Popover.Body>
        </Popover>
    );
});
