/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import moment from "moment";
import React, {useState } from "react"
import { Dropdown, Form, InputGroup, OverlayTrigger, Popover} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import { DraggableStopRow} from "./DraggableComponents.js";
import './RouteCard.scss'
import { useDroppable} from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import Route from "../Models/Route.js";
import { getNameMatches } from "../../../../tools.js";
import DropdownItemWithCheck from "../../../../components/DropdownItemWithCheck.js";
import ComboBox from "../../../../components/ComboBox.js";

export function StopDroppable({conflicts, route, setRoute, dragPlaceholderIndex, handleSetRouteStop}) {
    const { setNodeRef, isOver, active } = useDroppable({
        id: "stop-drop",
    });

    function handleRemoveStop(index){
        setRoute((prevState) => {
            const updatesStops = prevState.stops.toSpliced(index, 1);
            return new Route(prevState.uid, prevState.user, prevState.vehicle, prevState.name, prevState.date, prevState.notes, updatesStops);
        })
    }

    const isDraggingClientOrTerminal = (active?.id?.includes('client') || active?.id?.includes('terminal'))
    const isValidDrop = isOver && isDraggingClientOrTerminal;

    const firstStop = route.stops?.[0];
    const lastStop = route.stops?.[route.stops.length - 1];

    const firstStopDate = firstStop?.startTimeIsNextDay
    ? moment(route.date).add(1, 'day')
    : moment(route.date);

    const lastStopDate = lastStop?.endTimeIsNextDay
    ? moment(route.date).add(1, 'day')
    : moment(route.date);

    const firstStopStartTime = firstStop
    ? firstStopDate.clone().set({
        hour: moment(firstStop.startTime, "HH:mm").hours(),
        minute: moment(firstStop.startTime, "HH:mm").minutes(),
        })
    : null;

    const lastStopEndTime = lastStop
    ? lastStopDate.clone().set({
        hour: moment(lastStop.endTime, "HH:mm").hours(),
        minute: moment(lastStop.endTime, "HH:mm").minutes(),
        })
    : null;

    const totalHours =
    firstStopStartTime && lastStopEndTime
        ? lastStopEndTime.diff(firstStopStartTime, "minutes") / 60
        : 0;


    const dateOverflowSeverity = conflicts.dateOverflow.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const dateOverflowListItems = conflicts.dateOverflow.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const dateOverflowPopover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {dateOverflowListItems?.length > 0 ? dateOverflowListItems : <></>}
            </Popover.Body>
        </Popover>
    )

    return (
        <>
            <SortableContext items={route.stops} strategy={verticalListSortingStrategy}>
                {route.stops.map((stop, index) => (
                    <React.Fragment key={`stop-container-${index}`}>
                    {dragPlaceholderIndex === index && <PlaceholderRow key={`placeholder-${index}`} />}
                    <DraggableStopRow
                        key={`stop-${index}`}
                        stop={stop}
                        index={index}
                        allStops={route.stops}
                        conflicts={conflicts}
                        handleSetRouteStop={handleSetRouteStop}
                        handleRemoveStop={handleRemoveStop}
                    />
                    </React.Fragment>
                ))}
            </SortableContext> 
            <tr
                ref={setNodeRef}
                style={{
                    backgroundColor: isValidDrop ? '#cfe7ff' : undefined,
                    animation: 'fadeIn 0.3s ease-out',
                    transition: 'background-color 0.3s ease, transform 0.2s ease',
                    display: isDraggingClientOrTerminal &&  route.stops.length < 8 ? 'table-row' : 'none'
                }}
                >
                <td
                    colSpan={'100%'}
                    style={{
                        verticalAlign: 'middle',
                        textAlign: 'center',
                        borderRadius: '4px',
                    }}
                >
                    <span style={{ fontWeight: 'bold', color: '#0056b3' }}>Insert Here</span>
                </td>
            </tr>
            {route.stops.length > 7 && 
            <tr>
                <td
                    colSpan={'100%'}
                    style={{
                        verticalAlign: 'middle',
                        textAlign: 'center',
                        borderRadius: '4px',
                    }}
                >
                    <span><b>Maximum Stops Reached</b></span>
                </td>
                </tr>
            }
            <tr>
                <th colSpan="100%">
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 4}}>
                        { dateOverflowSeverity < 3 &&
                            <>
                                <span>Total Hours:</span>
                                <span>{totalHours}</span>
                            </>
                        }
                        {conflicts.dateOverflow.length > 0 && (
                        <span style={{ marginLeft: '0.5em' }}>
                            <OverlayTrigger placement="top" overlay={dateOverflowPopover}>
                                <FontAwesomeIcon
                                    icon={faTriangleExclamation}
                                    style={{
                                        color: dateOverflowSeverity === 1 ? 'gold' : dateOverflowSeverity === 2 ? 'orange' : 'red',
                                        marginRight: 0,
                                    }}
                                />
                            </OverlayTrigger>
                        </span>
                    )}
                    </div>
                </th>
            </tr>
        </>
    );
}

function PlaceholderRow() {
    return (
        <tr
            style={{
                backgroundColor: '#cfe7ff',
                animation: 'fadeIn 0.3s ease-out',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
            }}
        >
            <td
                colSpan={'100%'}
                style={{
                    verticalAlign: 'middle',
                    textAlign: 'center',
                    padding: '12px',
                    border: '1px dashed #007bff', 
                    borderRadius: '4px',
                }}
            >
                <span style={{ fontWeight: 'bold', color: '#0056b3' }}>Insert Here</span>
            </td>
        </tr>
    );
}



export function DriverDroppable({conflicts, route, routesForDay, templateMode, handleSetRoute, users, terminals}){
    const name = `${route?.user?.firstName} ${route?.user?.middleName ? `${route?.user?.middleName} ` : ''}${route?.user?.lastName}`;
    const [userSearch, setUserSearch] = useState(route.user ? name : '')
    const [includeNonDrivers, setIncludeNonDrivers] = useState(false)
    const [selectedTerminals, setSelectedTerminals] = useState(terminals.map(t=> t.uid));
    const { setNodeRef, isOver, active } = useDroppable({
        id: "driver-drop",
    });

    function handleSetSelectedTerminals(uid, isSelected){ 
        const newSelectedTerminals = isSelected ? selectedTerminals.filter(terminalIdentifier => terminalIdentifier != uid) : [...selectedTerminals, uid];
        setSelectedTerminals(newSelectedTerminals);
    }

    function sortByConflictSeverity(a, b){
        const aSeverity = a?.conflicts?.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0)
        const bSeverity = b?.conflicts?.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0)

        if(aSeverity < bSeverity){
            return -1
        } else if(aSeverity > bSeverity){
            return 1
        }

        return 0;
    }

    const isValidDrop = isOver && active?.id?.includes('driver');
    const userConflictSeverity = conflicts.user.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const userConflictListItems = conflicts.user.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const userPopover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {userConflictListItems?.length > 0 ? userConflictListItems : <></>}
            </Popover.Body>
        </Popover>
    )
    
    const filteredUsers = users.filter(user => {
        user.conflicts = [];

        // already scheduled conflict
        if(routesForDay.find(r => r?.user?.companyUserIdentifier === user.companyUserIdentifier && route?.uid !== r.uid)){
            user.conflicts.push({
                severity: 2,
                message: 'This user is already scheduled for this date on another route.'
            })
        }
    
        // availability conflict
        if(!parseInt(user.availability[(moment(route.date).day() + 1)%7])){
            user.conflicts.push({
                severity: 1,
                message: 'Current day falls ouside of user\'s availability.',
            })
        }
    
        // timeOff conflict
        if(!templateMode){
            let selectedTimeOff = route?.user?.timeOff?.find(to => moment(route.date).isBetween(moment(to.startDate), moment(to.endDate), undefined, '[]') && to.status !== -1)
            if(selectedTimeOff){
                const timeOffString = selectedTimeOff.isPto ? 'paid time-off' : selectedTimeOff.status === 1 ? 'unpaid time-off' : 'a pending time-off request';
                conflicts.user.push({
                    severity: 2,
                    message: `This user has ${timeOffString} on this date.`
                })
            }
        }

        return getNameMatches(user, userSearch) && (includeNonDrivers || user.title === 'Driver') && route?.user?.companyUserIdentifier !== user.companyUserIdentifier && selectedTerminals.includes(user.terminalIdentifier);
    }).sort(sortByConflictSeverity)

    const options = filteredUsers.map((user) => {
        const name = `${user.firstName} ${user.middleName ? `${user.middleName} ` : ''}${user.lastName}`;
        return {
            label: name,
            value: user,
            content: (
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>
                        <p style={{ margin: 0 }}>{`${user.firstName} ${user.middleName ? `${user.middleName} ` : ''}${user.lastName}`}</p>
                        <p style={{ margin: 0, opacity: .5 }}>{user.title}</p>
                    </div>
                    {user.conflicts.length > 0 && (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Popover style={{ position: 'fixed' }}>
                                    <Popover.Header></Popover.Header>
                                    <Popover.Body>
                                        {user.conflicts.map((element, index) => (
                                            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                                                <FontAwesomeIcon 
                                                    icon={faTriangleExclamation} 
                                                    style={{ 
                                                        color: element.severity === 0 
                                                            ? 'lightgreen' 
                                                            : element.severity === 1 
                                                            ? 'yellow' 
                                                            : element.severity === 2 
                                                            ? 'orange' 
                                                            : 'red' 
                                                    }} 
                                                />
                                                <p style={{ margin: 0 }}>{element.message}</p>
                                            </div>
                                        ))}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <FontAwesomeIcon 
                                icon={faTriangleExclamation} 
                                style={{ 
                                    color: user.conflicts.reduce((max, conflict) => Math.max(max, conflict.severity), 0) === 1 
                                        ? 'gold' 
                                        : user.conflicts.reduce((max, conflict) => Math.max(max, conflict.severity), 0) === 2 
                                        ? 'orange' 
                                        : 'red' 
                                }} 
                            />
                        </OverlayTrigger>
                    )}
                </div>
            )
        };
    });

    const usersFilters = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle  variant="outline-primary">Filters</Dropdown.Toggle>
            <Dropdown.Menu style={{position: 'fixed'}}>
                <Dropdown.Header>Assigned Terminals</Dropdown.Header>
                {terminals.map(t => {
                    const isSelected = selectedTerminals.includes(t.uid);
                    return <DropdownItemWithCheck key={t.uid} label={t.name} active={isSelected} onClick={() => handleSetSelectedTerminals(t.uid, isSelected)}/>
                })}
                <Dropdown.Divider/>
                <Dropdown.Header>Other</Dropdown.Header>
                <DropdownItemWithCheck label='Include Non-Drivers' active={includeNonDrivers} onClick={() => setIncludeNonDrivers(!includeNonDrivers)}/>
            </Dropdown.Menu>
        </Dropdown>
    )

    return (
        <tr
            ref={setNodeRef}
            style={{
                backgroundColor: isValidDrop ? '#cfe7ff' : undefined,
            }}
        >
            <td colSpan="100%" style={{verticalAlign: 'middle', padding: 12}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 12}}>
                        {!conflicts.user.length ? null : (
                            <OverlayTrigger placement="top" overlay={userPopover}>
                            <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                style={{
                                color:
                                    userConflictSeverity === 1
                                    ? 'gold'
                                    : userConflictSeverity === 2
                                    ? 'orange'
                                    : 'red',
                                }}
                            />
                            </OverlayTrigger>
                        )}
                        <span><b>Driver</b></span>
                    </div>
                    <ComboBox style={{width: 400}} placeholder={'Search a driver'} options={options} search={userSearch} filters={usersFilters} setSearch={setUserSearch} onSelect={(value) => handleSetRoute('user', value)} onClear={() => {setUserSearch(''); handleSetRoute('user', null)}}/>
                </div>
            </td>
        </tr>
    )
}

export function VehicleDroppable({conflicts, route, terminals, vehicles, handleSetRoute, routesForDay}){
    const { setNodeRef, isOver, active } = useDroppable({
        id: "vehicle-drop",
    });
    const [vehicleSearch, setVehicleSearch] = useState(route.vehicle ? route.vehicle.name : '')
    const [selectedTerminals, setSelectedTerminals] = useState(terminals.map(t=> t.uid));
        
    function handleSetSelectedTerminals(uid, isSelected){
        const newSelectedTerminals = isSelected ? selectedTerminals.filter(terminalIdentifier => terminalIdentifier != uid) : [...selectedTerminals, uid];
        setSelectedTerminals(newSelectedTerminals);
    }

    function sortByConflictSeverity(a, b){
        const aSeverity = a?.conflicts?.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0)
        const bSeverity = b?.conflicts?.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0)

        if(aSeverity < bSeverity){
            return -1
        } else if(aSeverity > bSeverity){
            return 1
        }

        return 0;
    }

    const isValidDrop = isOver && active?.id?.includes('vehicle');
    const vehicleConflictSeverity = conflicts.vehicle.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const vehicleConflictListItems = conflicts.vehicle.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0}}>{element.message}</p>
            </div>
        )
    });

    const filteredVehicles = vehicles.filter(vehicle => {
        // already scheduled conflict
        vehicle.conflicts = [];
        if(routesForDay.find(r => r?.vehicle?.uid === vehicle.uid && route?.uid !== r.uid)){
            vehicle.conflicts.push({
                severity: 2,
                message: 'This vehicle is already scheduled for this date on another route.'
            })
        }
        return vehicle.name.toLocaleLowerCase().includes(vehicleSearch.toLocaleLowerCase()) && route?.vehicle?.uid !== vehicle.uid && selectedTerminals.includes(vehicle.terminalIdentifier);
    }).sort(sortByConflictSeverity) 

    //////////////////////
    /// UI COMPONENTS
    //////////////////////

    const options = filteredVehicles.map((vehicle) => {
        return {
            label: vehicle.name,
            value: vehicle,
            content: (
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>{vehicle.name}</span>
                    {vehicle.conflicts.length > 0 && (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Popover style={{ position: 'fixed' }}>
                                    <Popover.Header></Popover.Header>
                                    <Popover.Body>
                                        {vehicle.conflicts.map((element, index) => (
                                            <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                                                <FontAwesomeIcon 
                                                    icon={faTriangleExclamation} 
                                                    style={{ 
                                                        color: element.severity === 0 
                                                            ? 'lightgreen' 
                                                            : element.severity === 1 
                                                            ? 'yellow' 
                                                            : element.severity === 2 
                                                            ? 'orange' 
                                                            : 'red' 
                                                    }} 
                                                />
                                                <p style={{ margin: 0 }}>{element.message}</p>
                                            </div>
                                        ))}
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <FontAwesomeIcon 
                                icon={faTriangleExclamation} 
                                style={{ 
                                    color: vehicle.conflicts.reduce((max, conflict) => Math.max(max, conflict.severity), 0) === 1 
                                        ? 'gold' 
                                        : vehicle.conflicts.reduce((max, conflict) => Math.max(max, conflict.severity), 0) === 2 
                                        ? 'orange' 
                                        : 'red' 
                                }} 
                            />
                        </OverlayTrigger>
                    )}
                </div>
            )
        };
    });

    const vehiclePopover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {vehicleConflictListItems?.length > 0 ? vehicleConflictListItems : <></>}
            </Popover.Body>
        </Popover>
    )

    const vehicleFilters = (
        <Dropdown autoClose='outside'>
            <Dropdown.Toggle variant="outline-primary">Filters</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Header>Assigned Terminals</Dropdown.Header>
                {terminals.map(t => {
                    const isSelected = selectedTerminals.includes(t.uid);
                    return <DropdownItemWithCheck key={t.uid} label={t.name} active={isSelected} onClick={() => handleSetSelectedTerminals(t.uid, isSelected)}/>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )

    return (
        <tr
        ref={setNodeRef}
        style={{
            backgroundColor: isValidDrop ? '#cfe7ff' : undefined,
        }}
    >
            <td colSpan="100%" style={{verticalAlign: 'middle', padding: 12}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 12}}>
                        {!conflicts.vehicle.length ? <></> :
                            <OverlayTrigger placement='top' overlay={vehiclePopover}>
                                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: vehicleConflictSeverity === 1 ? 'gold' : vehicleConflictSeverity === 2 ? 'orange'  : 'red'}}/>
                            </OverlayTrigger>
                        }
                        <span><b>Truck</b></span>
                    </div>
                    <ComboBox style={{width: 400}} placeholder={'Search a vehicle'} options={options} search={vehicleSearch} filters={vehicleFilters} setSearch={setVehicleSearch} onSelect={(value) => handleSetRoute('vehicle', value)} onClear={() => {setVehicleSearch(''); handleSetRoute('vehicle', null)}}/>
                </div>
            </td>
        </tr>
    )
}