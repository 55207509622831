/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './HumanResources.css';
import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import HRUserProfile from './HRUserProfile';
import HRUserPay from './HRUserPay/HRUserPay';
import HRUserBWC from './BWC/HRUserBWC';
import HRUserTimesheet from './HRUserTimesheet/HRUserTimesheet';
import Cookies from 'universal-cookie';
import HRNotes from './HRNotes/HRNotes';
import HRUserLoan from './HRUserLoan/HRUserLoan';
import { Dropdown, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { checkPermission } from '../../../tools';
import HRUserPermissions from './HRUserPermissions';
import HRUserSchedule from './HRUserSchedule/HRUserSchedule';
import HRUserPto from './HRUserPTO/HRUserPto';
import HRUserDocuments from './HRDocuments/HRUserDocuments';

export default function HumanResourcesUser({selectedEmployee, selectedCompany, handleGoBackToHub}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [selectedBwcRecord, setSelectedBwcRecord] = useState(undefined); 
    const permissions = new Cookies().get('permissions');
    const userData = new Cookies().get('userData');
    const restrictWhoSeesPay = new Cookies().get('restrictWhoSeesPay');
    const auth = new Cookies().get('auth')
    

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, [])


    function handleWindowResize() {
        setWindowWidth(window.innerWidth);
    }

    function handleChangeTab(tab) {
        setActiveTabKey(tab);
    }

    function handleJumpToBwcRecord(bwcRecord) {
        setSelectedBwcRecord(bwcRecord);
        setActiveTabKey('BWC');
    }

    const tabs = {
        'Profile': <HRUserProfile selectedEmployee={selectedEmployee} selectedCompany={selectedCompany}/>,
        'Schedule': <HRUserSchedule selectedEmployee={selectedEmployee} selectedCompany={selectedCompany}/>,
        'Timesheet': <HRUserTimesheet selectedEmployee={selectedEmployee} selectedCompany={selectedCompany} handleJumpToBwcRecord={handleJumpToBwcRecord}/>,
        'Pay': <HRUserPay selectedEmployee={selectedEmployee} selectedCompany={selectedCompany}/>,
        'PTO': <HRUserPto selectedEmployee={selectedEmployee} selectedCompany={selectedCompany}/>,
        'Loans & FTD': <HRUserLoan selectedEmployee={selectedEmployee} selectedCompany={selectedCompany}/>,
        'BWC': <HRUserBWC selectedEmployee={selectedEmployee} selectedCompany={selectedCompany} windowWidth={windowWidth} selectedBwcRecord={selectedBwcRecord} setSelectedBwcRecord={setSelectedBwcRecord}/>,
        'Documents': <HRUserDocuments selectedEmployee={selectedEmployee} selectedCompany={selectedCompany}/>,
        'Notes': <HRNotes selectedEmployee={selectedEmployee} selectedCompany={selectedCompany}/>,
        'Permissions': <HRUserPermissions selectedEmployee={selectedEmployee} selectedCompany={selectedCompany}/>,
    }

    let tabNames = [];
    if (checkPermission('hrProfile')) {
        tabNames.push('Profile');
    }
    if (checkPermission('hrSchedule')) {
        tabNames.push('Schedule');
    }
    if (checkPermission('hrTimesheet')) {
        tabNames.push('Timesheet');
    }
    if (checkPermission('hrPay') && (restrictWhoSeesPay == 0 || userData.title == 'AO' || userData.title == 'Admin' || selectedEmployee?.title == 'Driver' || userData.companyUserIdentifier == selectedEmployee.companyUserIdentifier || !auth.companyUserIdentifier)) {
        tabNames.push('Pay');
    }
    if (checkPermission('hrPay')) {
        tabNames.push('PTO');
    }
    if(checkPermission('hrLoans')){
        tabNames.push('Loans & FTD');
    }
    if (checkPermission('hrBwc')) {
        tabNames.push('BWC');
    }
    if (checkPermission('hrDocuments')) {
        tabNames.push('Documents');
    }
    if (checkPermission('hrProfile'))  {
        tabNames.push('Notes');
    }
    if (checkPermission('hrPermissions')) {
        tabNames.push('Permissions');
    }

    const [activeTabKey, setActiveTabKey] = useState(tabNames[0]);
    
    const tabElements = tabNames.map((tab) => {
        return (
            <Button key={tab} variant='outline-primary' style={activeTabKey == tab ? {paddingLeft: 0, paddingRight: 0, backgroundColor: 'var(--bs-primary)', color: 'white'} : {paddingLeft: 0, paddingRight: 0}} onClick={() => {handleChangeTab(tab)}}>
                {tab}
            </Button>
        )
    });

    const tabDropdownItems = tabNames.map((tab) => {
        return (
            <Dropdown.Item key={tab} active={activeTabKey == tab} onClick={() => {handleChangeTab(tab)}}>{tab}</Dropdown.Item>
        )
    })    

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100%'}}>
            {windowWidth >= 1200 ? 
                <div style={{display: 'flex', alignItems: 'flex-start', gap: 12}}>
                    <Button variant='outline-primary' style={{display: 'flex', gap: 4, alignItems: 'center'}} onClick={handleGoBackToHub}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                        <span>Back to Hub</span>
                    </Button>
                    <div style={{flex: 1}}>
                        <ButtonGroup style={{display: 'flex'}}>
                            {tabElements}
                        </ButtonGroup>
                    </div>
                    <h4 style={{textAlign: 'center', opacity: 0.65, fontWeight: 'bold', marginBottom: 0, marginTop: 4}}>{`${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</h4>
                </div>
            :
                <div style={{display: 'flex', justifyContent: 'space-between', gap: 6, flexWrap: 'wrap'}}>
                    <Button variant='outline-primary' style={{display: 'flex', gap: 4, alignItems: 'center', minWidth: 80}} onClick={handleGoBackToHub}>
                        <FontAwesomeIcon icon={faChevronLeft}/>
                        <span>Back to Hub</span>
                    </Button>
                    <Dropdown>
                        <Dropdown.Toggle variant='outline-primary'>{`Viewing: ${activeTabKey}`}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {tabDropdownItems}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            }
            <hr/>
            <div style={{display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 12, paddingRight: 12, overflowY: 'hidden', height: '100%'}}>
                {tabs[activeTabKey] ?? tabs[tabNames[0]] ?? <span>No Permissions</span>}
            </div>
        </div>
    )
}