/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {  useMemo, useState } from "react"
import { ApiRequest } from "../../../../ApiManager.tsx";
import {  Button, ButtonGroup} from "react-bootstrap";
import {  stringTimeToInt, intToTime, getFakeUID} from "../../../../tools.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faLocationDot, faMinusCircle, faWarehouse } from "@fortawesome/free-solid-svg-icons";
import SearchBar from "../../../../components/SearchBar.js";
import Route from '../Models/Route.js';
import CustomButton from "../../../../components/CustomButton.js";
import { DraggableClient, DraggableTerminal } from "./DraggableComponents.js";
import CustomToolTip from "../../../../components/CustomToolTip.js";
import RouteCard from "./RouteCard.js";
import  "./RouteEditor.css";
import { getConflicts } from "../Scheduling.js";
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";

export default function RouteEditor({selectedRoute, setSelectedRoute, setShowRouteEditor, clients, terminals, vehicles, users, routesForDay, selectedDate, templateMode, selectedTemplate, handleRoutesCrud, allRoutes}) {
    const [route, setRoute] = useState(() => selectedRoute ? new Route(selectedRoute.uid, selectedRoute.user, selectedRoute.vehicle, selectedRoute.name, selectedDate, selectedRoute.notes, selectedRoute.stops) : Route.initDefault(selectedDate));
    const [stemTimes, setStemTimes] = useState([]);
    const [isDraggingClientOrTerminal, setIsDraggingClientOrTerminal] = useState(false);
    const [expandSection, setExpandSection] = useState('');
    const [clientSearch, setClientSearch] = useState('');
    const [terminalSearch, setTerminalSearch] = useState('');
    const [isValidating, setIsValidating] = useState(false);
    const [isAutoFilling, setIsAutoFilling] = useState(false);
    const [dragPlaceholderIndex, setDragPlaceholderIndex] = useState(undefined);
    const [active, setActive] = useState()
    const activeItem = useMemo(() => {
        if(active?.id?.includes('client')){
            return clients.find((client) => client.uid == active.id.slice(7));
        }
        if(active?.id?.includes('terminal')){
           return terminals.find((terminal) => terminal.uid == active.id.slice(9));
        }
        return undefined;
    }, [active, clients, terminals])

    const conflicts = useMemo(() => {
        return getConflicts(route, routesForDay, allRoutes, stemTimes, selectedDate, templateMode);
    }, [route, stemTimes, routesForDay, selectedDate, templateMode])

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
    );

    function handleCalculateStemForRoute(willAutoFill = false){
        // Determine if any stem times are needed from api call
        const stopsToSend = [];
        for(let i = 1; i < route.stops.length; i++){
            if(!stemTimes.find(s => (s.startAddressIdentifier === route.stops[i-1].address.uid && s.endAddressIdentifier === route.stops[i].address.uid))){
                if(!stopsToSend.length || route.stops[i-1].uid !== stopsToSend[stopsToSend.length-1].uid){
                    stopsToSend.push(route.stops[i-1]);
                }
                stopsToSend.push(route.stops[i]);
            }
        }

        if(stopsToSend.length){
            new ApiRequest('scheduling', 'calculateStem', willAutoFill ? setIsAutoFilling : setIsValidating, (response) => {
                const newStemTimes = [...stemTimes, ...response.stemTimes];
                setStemTimes(newStemTimes);
                if(willAutoFill){
                    autoFillRoute(newStemTimes);
                }
            }).withData({stops: stopsToSend}).send();
        }else{
            if(willAutoFill){
                autoFillRoute(stemTimes);
            }
        }
    }

    function autoFillRoute(stemTimes) {

        const roundToNearest15AndConvertToTime = (minutes) => {
            return intToTime(Math.ceil(minutes / 15) * 15);
        }

        const checkIfAnyPreviousStopsTimesAreNextDay = (stops, index) => {
            return stops.slice(0, index).some((stop) => stop.endTimeIsNextDay || stop.startTimeIsNextDay);
        }

        setRoute((prevState) => {
            const newRoute = prevState.duplicate();
    
            newRoute.stops.forEach((stop, index) => {
                if (index === 0) {
                    //Setting the endTime and endTimeIsNextDay for the first stop
                    if (!stop.endTimeIsLocked || !stop.endTime) {
                        const intTimeToAutoFill = stringTimeToInt(stop.startTime) + stop.defaultLoadTime;
                        stop.endTime = roundToNearest15AndConvertToTime(intTimeToAutoFill);
                        stop.endTimeIsNextDay = intTimeToAutoFill >= 1440 || stop.startTimeIsNextDay;
                    }
                } else {
                    const prevStop = newRoute.stops[index - 1];
                    const stemTime = stemTimes.find(
                        (s) =>
                            s.startAddressIdentifier === prevStop.address.uid &&
                            s.endAddressIdentifier === stop.address.uid
                    )?.stemTime || 0;
                
                    //Setting the startTime and startTimeIsNextDay for the stop at current index
                    if (!stop.startTimeIsLocked || !stop.startTime) {
                        const intTimeToAutoFill = stringTimeToInt(prevStop.endTime) + stemTime;
                        stop.startTime = roundToNearest15AndConvertToTime(intTimeToAutoFill);
                        stop.startTimeIsNextDay = intTimeToAutoFill >= 1440 || checkIfAnyPreviousStopsTimesAreNextDay(newRoute.stops, index);
                    }
                
                    //Setting the endTime and endTimeIsNextDay for the stop at current index
                    if (!stop.endTimeIsLocked || !stop.endTime) {
                        const intTimeToAutoFill = stringTimeToInt(stop.startTime) + stop.defaultLoadTime;
                        stop.endTime = roundToNearest15AndConvertToTime(intTimeToAutoFill);
                        stop.endTimeIsNextDay = intTimeToAutoFill >= 1440 || checkIfAnyPreviousStopsTimesAreNextDay(newRoute.stops, index) || stop.startTimeIsNextDay;
                    }
                }
            });
    
            return newRoute;
        });
    }

    function handleLockAllRouteInTimes(){
        const allSelected = route.stops.every((routeStop) => routeStop.endTimeIsLocked)
        setRoute((prevState) => {
            const newRoute = new Route(prevState.uid, prevState.user, prevState.vehicle, prevState.name, prevState.date, prevState.notes, prevState.stops);
            newRoute.stops.forEach((routeStop) => routeStop.endTimeIsLocked = !allSelected)
            return newRoute;
        })
    }

    function handleLockAllRouteOutTimes(){
        const allSelected = route.stops.every((routeStop) => routeStop.startTimeIsLocked)
        setRoute((prevState) => {
            const newRoute = new Route(prevState.uid, prevState.user, prevState.vehicle, prevState.name, prevState.date, prevState.notes, prevState.stops);
            newRoute.stops.forEach((routeStop) => routeStop.startTimeIsLocked = !allSelected)
            return newRoute;
        })
    }

    function handleSetRoute(key, value){
        setRoute((prevState) => {
            const newRoute = new Route(prevState.uid, prevState.user, prevState.vehicle, prevState.name, prevState.date, prevState.notes, prevState.stops);
            newRoute[key] = value;
            return newRoute;
        });
    }

    function handleSetRouteStop(key, value, stopIndex){
        setRoute((prevState) => {
            const newRoute = new Route(prevState.uid, prevState.user, prevState.vehicle, prevState.name, prevState.date, prevState.notes, prevState.stops);
            newRoute.stops[stopIndex][key] = value;
            return newRoute;
        });
    }

    function onDragEnd(e) {
        setDragPlaceholderIndex(undefined);
    
        const { active, over } = e;
        const data = active?.data?.current;
    
        if (!active || !over) return;
    
        const activeId = active.id;
        const overId = over.id;
    
        switch (true) {

            // CLIENT DRAGGABLE IS DROPPED OVER ALWAYS PRESENT INSERT DROP
            case activeId.includes('client') && overId === 'stop-drop': {
                if (route.stops.length < 8) {
                    setRoute((prevState) => {
                        return new Route(
                            prevState.uid,
                            prevState.user,
                            prevState.vehicle,
                            prevState.name,
                            prevState.date,
                            prevState.notes,
                            [...prevState.stops, { ...data, clientIdentifier: data.uid, id: `stop${getFakeUID()}` }]
                        );
                    });
                }
                break;
            }
    
            // CLIENT DRAGGABLE IS DROPPED OVER PLACEHOLDER DROPS THAT APPEAR ABOVE OTHER STOPS
            case activeId.includes('client') && overId.includes('stop') && dragPlaceholderIndex !== undefined: {
                if (route.stops.length < 8) {
                    setRoute((prevState) => {
                        const newStops = [
                            ...prevState.stops.slice(0, dragPlaceholderIndex),
                            { ...data, clientIdentifier: data.uid, id: `stop${getFakeUID()}` },
                            ...prevState.stops.slice(dragPlaceholderIndex),
                        ];
    
                        return new Route(
                            prevState.uid,
                            prevState.user,
                            prevState.vehicle,
                            prevState.name,
                            prevState.date,
                            prevState.notes,
                            newStops
                        );
                    });
                }
                break;
            }

            // TERMINAL DRAGGABLE IS DROPPED OVER ALWAYS PRESENT INSERT DROP
            case activeId.includes('terminal') && overId === 'stop-drop': {
                if (route.stops.length < 8) {
                    setRoute((prevState) => {
                        return new Route(
                            prevState.uid,
                            prevState.user,
                            prevState.vehicle,
                            prevState.name,
                            prevState.date,
                            prevState.notes,
                            [...prevState.stops, { ...data, terminalIdentifier: data.uid, id: `stop${getFakeUID()}` }]
                        );
                    });
                }
                break;
            }

            // TERMINAL DRAGGABLE IS DROPPED OVER PLACEHOLDER DROPS THAT APPEAR ABOVE OTHER STOPS
            case activeId.includes('terminal') && overId.includes('stop') && dragPlaceholderIndex !== undefined: {
                if (route.stops.length < 8) {
                    setRoute((prevState) => {
                        const newStops = [
                            ...prevState.stops.slice(0, dragPlaceholderIndex),
                            { ...data, terminalIdentifier: data.uid, id: `stop${getFakeUID()}` },
                            ...prevState.stops.slice(dragPlaceholderIndex),
                        ];
    
                        return new Route(
                            prevState.uid,
                            prevState.user,
                            prevState.vehicle,
                            prevState.name,
                            prevState.date,
                            prevState.notes,
                            newStops
                        );
                    });
                }
                break;
            }
    
            // ALREADY EXISTING STOP IS SORTED WITHIN TABLE
            case activeId.includes('stop') && overId && activeId !== overId: {
                const oldIndex = route.stops.findIndex((stop) => stop.id === activeId);
                const newIndex = route.stops.findIndex((stop) => stop.id === overId);
                const newStops = arrayMove(route.stops, oldIndex, newIndex);
    
                setRoute((prevState) => {
                    return new Route(
                        prevState.uid,
                        prevState.user,
                        prevState.vehicle,
                        prevState.name,
                        prevState.date,
                        prevState.notes,
                        newStops
                    );
                });
                break;
            }
    
            default:
                break;
        }
    }
    

    function onDragOver({ active, over }) {
        setDragPlaceholderIndex(undefined);
        if(route.stops.length > 7){
            return
        }
        if (active?.id?.includes('client') && over?.id?.includes('stop')) {
            const overIndex = route.stops.findIndex((stop) => stop.id === over.id);
            setDragPlaceholderIndex(overIndex);
        }
        if (active?.id?.includes('terminal') && over?.id?.includes('stop')) {
            const overIndex = route.stops.findIndex((stop) => stop.id === over.id);
            setDragPlaceholderIndex(overIndex);
        }
    }

    function renderClientDraggable(client){
        return (
            <DraggableClient client={client} stops={route.stops}/>
        )
    }

    function renderTerminalDraggable(terminal){
        return (
            <DraggableTerminal key={terminal.uid} terminal={terminal} stops={route.stops}/>
        )
    }

    ////////////////////
    /// Variables
    ////////////////////

    const filteredTerminals = terminals.filter(t => {
        return t.name.toLocaleLowerCase().includes(terminalSearch.toLocaleLowerCase());
    })

    const filteredClients = clients.filter(c => {
        return  c.name.toLocaleLowerCase().includes(clientSearch.toLocaleLowerCase());
    })

    //////////////////////////
    /// UI ELEMENTS
    //////////////////////////

    const draggableTerminals = filteredTerminals.map((t, i)=> <DraggableTerminal key={t.uid} index={i} terminal={t} stops={route.stops}/>)
    const draggableClients = filteredClients.map((c, i)=> <DraggableClient key={c.uid} index={i} client={c} stops={route.stops}/>)

    return (
        <DndContext sensors={sensors} onDragStart={({active}) => setActive(active)} onDragEnd={onDragEnd} onDragOver={onDragOver}>
                <div style={{ padding: '0 18px'}}>
                    <div style={{display: 'flex', gap: 8, justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: 8, paddingTop: 8}}>
                        <Button style={{minWidth: 170, flex: 1}} variant='outline-primary' onClick={() => {setSelectedRoute(null); setShowRouteEditor(false)}}>
                            <FontAwesomeIcon style={{marginRight: 4}} icon={faChevronLeft}/>
                            <span>Back to Routes</span>
                        </Button>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingBottom: 8,
                        borderRadius: 50,
                    }}>
                        <ButtonGroup style={{ display: 'flex', flex: 1 }}>
                            <Button
                                onClick={() => { setExpandSection('terminals'); }}
                                style={{ flex: 1 ,
                                    borderBottomLeftRadius: expandSection ? 0 : 6,
                                }}
                                variant="outline-primary"
                            >
                                <FontAwesomeIcon icon={faWarehouse}/>
                            </Button>
                            <Button
                                onClick={() => { setExpandSection('clients'); }}
                                style={{
                                    flex: 1,
                                    borderBottomRightRadius: expandSection ? 0 : 6,
                                }}
                                variant="outline-primary"
                            >
                                <FontAwesomeIcon icon={faLocationDot}/>
                            </Button>
                        </ButtonGroup>
                        {/* TERMINALS */}
                        {expandSection === 'terminals' && (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 8,
                                padding: '12px',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: 8,
                                    marginBottom: 8
                                }}>
                                    <FontAwesomeIcon
                                        onClick={() => { setExpandSection(''); setTerminalSearch(''); }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'var(--bs-primary)',
                                            width: 18,
                                            height: 18
                                        }}
                                        icon={faMinusCircle}
                                    />
                                    <h5 style={{
                                        textAlign: 'center',
                                        margin: 0,
                                        fontWeight: 'bold',
                                        color: 'var(--bs-dark)'
                                    }}>
                                        Terminals
                                        <FontAwesomeIcon
                                            style={{  marginLeft: 12 }}
                                            color="var(--bs-primary)"
                                            icon={faWarehouse}
                                        />
                                    </h5>
                                    <div></div>
                                </div>
                                <SearchBar label="Search" value={terminalSearch} setValue={setTerminalSearch} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 8,
                                    overflowY: 'auto',
                                    padding: '6px',
                                    maxHeight: 300,
                                    scrollbarWidth: 'thin',
                                }}>
                                    {draggableTerminals}
                                </div>
                            </div>
                        )}
                        {/* CLIENTS */}
                        {expandSection === 'clients' && (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 8,
                                padding: '12px',
                            }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: 8,
                                    marginBottom: 8
                                }}>
                                    <FontAwesomeIcon
                                        onClick={() => { setExpandSection(''); setClientSearch(''); }}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'var(--bs-primary)',
                                            width: 18,
                                            height: 18
                                        }}
                                        icon={faMinusCircle}
                                    />
                                    <h5 style={{
                                        textAlign: 'center',
                                        margin: 0,
                                        fontWeight: 'bold',
                                        color: 'var(--bs-dark)'
                                    }}>
                                        Clients
                                        <FontAwesomeIcon
                                            style={{  marginLeft: 12 }}
                                            color="var(--bs-primary)"
                                            icon={faLocationDot}
                                        />
                                    </h5>
                                    <div></div>
                                </div>
                                <SearchBar label="Search" value={clientSearch} setValue={setClientSearch} />
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 8,
                                    overflowY: 'auto',
                                    padding: '6px',
                                    maxHeight: 300,
                                    scrollbarWidth: 'thin',
                                }}>
                                    {draggableClients}
                                </div>
                            </div>
                        )}
                    </div>
                    <hr style={{marginBottom: 4}}/>
                    <div style={{display: 'flex', marginLeft: 'auto', gap: 6, flexWrap: "wrap", justifyContent: 'end', width: '100%', margin: '12px 0'}}>
                        {route.stops.length > 1 && 
                            <div style={{display: 'flex'}}>
                                <CustomButton isLoading={isValidating} style={{borderRight: 'none', borderTopRightRadius: 0, borderBottomRightRadius: 0}} onClick={() => handleCalculateStemForRoute()} variant="outline-primary">
                                    Validate Route
                                </CustomButton >
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, border: '1px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                                    <CustomToolTip placement='bottom' text={'Calculate the travel time between current stops and validate current route.'}/>
                                </div>
                            </div>
                        }
                        {route.stops.length > 1 && 
                            <div style={{display: 'flex'}}>
                                <CustomButton isLoading={isAutoFilling} style={{borderRight: 'none', borderTopRightRadius: 0, borderBottomRightRadius: 0}} disabled={!route.stops?.[0].startTime} onClick={() => {handleCalculateStemForRoute(true)}}  variant="outline-primary">
                                    Autofill Route
                                </CustomButton >
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 25, border: '1px solid var(--bs-primary)', borderTopRightRadius: 6, borderBottomRightRadius: 6}}>
                                    <CustomToolTip placement='bottom' text={'(Requires first stop to have a Time-In) Autofill the entire route card using calculated stem times and default load times. Use the lock checkbox columns to prevent certain times from being overwritten.'}/>
                                </div>
                            </div>
                        }
                    </div>
                    <RouteCard 
                        isDraggingClientOrTerminal={isDraggingClientOrTerminal} 
                        setIsDraggingClientOrTerminal={setIsDraggingClientOrTerminal}
                        handleSetRoute={handleSetRoute} 
                        handleSetRouteStop={handleSetRouteStop} 
                        handleLockAllRouteInTimes={handleLockAllRouteInTimes}
                        handleLockAllRouteOutTimes={handleLockAllRouteOutTimes}
                        conflicts={conflicts} 
                        selectedTemplate={selectedTemplate} 
                        templateMode={templateMode} 
                        selectedRoute={selectedRoute} 
                        handleRoutesCrud={handleRoutesCrud} 
                        route={route}
                        dragPlaceholderIndex={dragPlaceholderIndex}
                        setRoute={setRoute}
                        routesForDay={routesForDay}
                        users={users}
                        terminals={terminals}
                        vehicles={vehicles}
                    />
                </div>
            <DragOverlay>
                {activeItem && (active?.id?.includes('client') ? renderClientDraggable(activeItem) : active?.id?.includes('terminal') ? renderTerminalDraggable(activeItem) : null)}
            </DragOverlay>
        </DndContext>
    )
}