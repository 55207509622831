/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useMemo, useState } from "react"
import { Button, Col, Dropdown, DropdownButton, FloatingLabel, Form, InputGroup, Modal, Row } from "react-bootstrap";
import DropdownItemWithCheck from "../../../../components/DropdownItemWithCheck.js";
import CustomControl from "../../../../components/CustomStateControls/CustomControl.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import CustomButton from "../../../../components/CustomButton.js";
import { ApiRequest } from "../../../../ApiManager.tsx";
import AlertConfirmation from "../../../../components/AlertModals/AlertConfirmation.js";
import ComboBox from "../../../../components/ComboBox.js";

export default function VehicleEditorModal({selectedVehicle, vehicles, setVehicles, hideModal, terminals}){
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [vehiclesToCreate, setVehiclesToCreate] = useState([{name: '', terminalIdentifier: null, weight: 0, height: 0, width: 0}])
    const [vehicleToEdit, setVehicleToEdit] = useState(selectedVehicle);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [terminalSearch, setTerminalSearch] = useState(selectedVehicle ? terminals.find((terminal) => terminal.uid == selectedVehicle.terminalIdentifier).name : '')

    function handleCreateVehicles(){
        const encodedData = {
            vehicles: vehiclesToCreate.map((vehicle) => ({
                ...vehicle,
                weight: (vehicle.weight < 0 || !vehicle) ? 0 : vehicle.weight
            }))
        }

        new ApiRequest('myCompany', 'createVehicles', setIsSubmitting, (response) => {
            let count = 0;
            vehiclesToCreate.forEach(v => v.uid = response.uids[count++]);
            setVehicles([...vehicles, ...vehiclesToCreate]);
            hideModal();
        }).withData(encodedData).send()
    }

    function handleUpdateVehicle(){
        const encodedData = {
            vehicle: ({
                ...vehicleToEdit,
                weight: (vehicleToEdit.weight < 0 || !vehicleToEdit) ? 0 : vehicleToEdit.weight
            })
        }

        new ApiRequest('myCompany', 'updateVehicle', setIsSubmitting, () => {
            const newArray = Array.from(vehicles);
            newArray[newArray.findIndex(v => v.uid === selectedVehicle.uid)] = vehicleToEdit;
            setVehicles(newArray);
            hideModal();
        }).withData(encodedData).send();
    }

    function handleDeleteVehicle(){
        new ApiRequest('myCompany', 'deleteVehicle', setIsDeleting, () => {
            setVehicles(vehicles.filter(v => v.uid !== vehicleToEdit.uid));
            setShowDeleteModal(false);
            hideModal();
        }).withUid(vehicleToEdit.uid).send();
    }

    function handleAddVehicle(){
        setVehiclesToCreate([...vehiclesToCreate, {name: '', terminalIdentifier: null}])
    }

    function handleRemoveVehicle(index){
        const newArray = Array.from(vehiclesToCreate);
        const newVehicles = newArray.toSpliced(index, 1);
        setVehiclesToCreate(newVehicles);
    }

    function handleSetVehicles(key, index, value) {
        setVehiclesToCreate((prevVehicles) =>
            prevVehicles.map((vehicle, i) =>
                i === index ? { ...vehicle, [key]: value } : vehicle
            )
        );
    }
    
    function handleSetVehicle(key, value) {
        setVehicleToEdit((prevVehicle) => ({
            ...prevVehicle,
            [key]: value,
        }));
    }

    function checkIfVehiclesToCreateIsValid(){
       return vehiclesToCreate.reduce((acc, el) => {
            if(!acc)
                return false;

            if(!el.terminalIdentifier || !el.name){
                return false;
            }

            return acc;
       }, true)
    }

    const filteredTerminals = useMemo(() => {
        return terminals.filter((terminal) => 
            terminal.name.toLocaleLowerCase().includes(terminalSearch.toLocaleLowerCase())
        );
    }, [terminalSearch, terminals]);
    

    const terminalOptions = filteredTerminals.map((terminal) => {
        return {
            label: terminal.name,
            value: terminal.uid,
        };
    });
    
    const vehicleList = vehiclesToCreate.map((vehicle, index) => {
        return (
            <div key={index} style={{marginBottom: 32, padding: 10, border: '1px solid lightgray', borderRadius: 6}}>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bolder', margin: 0, height: 30, width: 30, marginBottom: 8, color: 'white', borderRadius: '100%', padding: 12, backgroundColor: 'var(--bs-primary)'}}>{index+1}</div>
                    <Button variant="outline-danger" style={{borderRadius: '100%', height: 30, width: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 8}} onClick={() => handleRemoveVehicle(index)}><FontAwesomeIcon icon={faTrash}/></Button>
                </div>
                <Row key={index}>
                    <CustomControl floatingLabel breakpoints={{lg: 12}} maxLength={30} label={'Name'} value={vehicle.name} setValue={(value) => handleSetVehicles('name', index, value)}/>
                    <Col lg={6} style={{marginBottom: 6}}>
                        <ImperialMeasurementControl label='Height' value={vehicle.height} setValue={(value) => handleSetVehicles('height', value, index)}/>
                    </Col>
                    <Col lg={6} style={{marginBottom: 6}}>
                        <ImperialMeasurementControl label='Width' value={vehicle.weight} setValue={(value) => handleSetVehicles('width', value, index)}/>
                    </Col>
                    <CustomControl breakpoints={{lg: 6}} floatingLabel label='Weight' type='number' maxInt={100000} value={vehicle.weight} setValue={(value) => handleSetVehicles('weight', value, index)}/>
                    <Col lg={6}>
                        <ComboBox style={{padding: 16}} search={terminalSearch} setSearch={setTerminalSearch} options={terminalOptions} placeholder={'Select a Terminal'} onSelect={(value) => handleSetVehicles('terminalIdentifier', value, index)} onClear={() => handleSetVehicles('terminalIdentifier', null, index)}/>
                    </Col>
                </Row>
            </div>
        )
    })

    return ( 
        <>
            {selectedVehicle ?
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Vehicle</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <CustomControl breakpoints={{lg: 12}} floatingLabel label='Name' maxLength={30} value={vehicleToEdit.name} setValue={(value) => handleSetVehicle('name', value)}/>
                            <Col lg={6} style={{marginBottom: 6}}>
                                <ImperialMeasurementControl label='Height' value={vehicleToEdit.height} setValue={(value) => handleSetVehicle('height', value)}/>
                            </Col>
                            <Col lg={6} style={{marginBottom: 6}}>
                                <ImperialMeasurementControl label='Width' value={vehicleToEdit.width} setValue={(value) => handleSetVehicle('width', value)}/>
                            </Col>
                            <CustomControl breakpoints={{lg: 6}} floatingLabel label='Weight' type='number' maxInt={100000} value={vehicleToEdit.weight} setValue={(value) => handleSetVehicle('weight', value)}/>
                            <Col lg={6}>
                                <ComboBox style={{padding: 16}} search={terminalSearch} setSearch={setTerminalSearch} options={terminalOptions} placeholder={'Select a Terminal'} onSelect={(value) => handleSetVehicle('terminalIdentifier', value)} onClear={() => handleSetVehicle('terminalIdentifier', null)}/>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer >
                        <button onClick={() => setShowDeleteModal(true)} style={{marginRight: 'auto',border: 'none', marginRight: 2, boxShadow: 'none', color: 'red', backgroundColor: 'transparent'}}>Delete Vehicle</button>
                        <CustomButton isLoading={isSubmitting} variant='outline-primary' onClick={handleUpdateVehicle}>Save Changes</CustomButton>
                    </Modal.Footer>
                    <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                        <AlertConfirmation variant='outline-danger' isLoading={isDeleting} callBack={handleDeleteVehicle} title='Delete Vehicle' message='Deleting this vehicle will also delete any routes that are associated with this vehicle (including routes within templates). Are you sure you want to delete this vehicle?' buttonLabel='Delete'/>
                    </Modal>
                </>
            :
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Vehicle(s)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {vehicleList}
                        <Button variant="outline-primary" style={{marginTop: 12, marginLeft: 'auto', display: 'block'}} onClick={handleAddVehicle}><FontAwesomeIcon icon={faPlus}/> Add Vehicle</Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton isLoading={isSubmitting} variant='outline-primary' disabled={vehiclesToCreate.length < 1 || !checkIfVehiclesToCreateIsValid()} onClick={handleCreateVehicles}>Submit</CustomButton>
                    </Modal.Footer>
                </>
            }
        </>
    )
}

function ImperialMeasurementControl({label, value = 0, setValue}){
    const initialFeet = Math.floor(value / 12);
    const initialRemainderInches = value % 12;
    const [feet, setFeet] = useState(initialFeet);
    const [inches, setInches] = useState(initialRemainderInches);

    const handleChange = (newFeet, newInches) => {
        setFeet(newFeet);
        setInches(newInches);
        if (setValue) {
            setValue(newFeet * 12 + newInches);
        }
      };
    
      const feetOptions = Array.from({ length: 20 }, (_, i) => i); 
      const inchesOptions = Array.from({ length: 12 }, (_, i) => i); 

      return (
        <Form.Group>
            <FloatingLabel label={label}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', padding: 9, gap: 8, border: '1px solid lightgray', borderRadius: 6}}>
                    <div>
                        <InputGroup>
                            {/* Feet Dropdown */}
                            <DropdownButton
                                id="dropdown-feet"
                                variant="outline-primary"
                                title={`${feet} ft`}
                            >
                                 <div style={{ maxHeight: 250, overflowY: 'auto' }}>
                                    {feetOptions.map((f) => (
                                        <Dropdown.Item
                                        key={f}
                                        onClick={() => handleChange(f, inches)}
                                        >
                                            {f} ft
                                        </Dropdown.Item>
                                    ))}
                                </div>
                            </DropdownButton>

                            {/* Inches Dropdown */}
                            <DropdownButton
                                id="dropdown-inches"
                                title={`${inches} in`}
                                variant="outline-primary"
                            >
                                <div style={{ maxHeight: 250, overflowY: 'auto' }}>
                                    {inchesOptions.map((inch) => (
                                        <Dropdown.Item
                                            key={inch}
                                            onClick={() => handleChange(feet, inch)}
                                        >
                                            {inch} in
                                        </Dropdown.Item>
                                    ))}
                                </div>
                            </DropdownButton>
                        </InputGroup>
                    </div>
                </div>
            </FloatingLabel>
        </Form.Group>
    );
}

