/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Profile from "./views/Account/Profile/Profile";
import TimeClock from "./views/Account/TimeClock/TimeClock";
import MyCompany from "./views/ISPManagement/MyCompany/MyCompany";
import { faCalendar, faClock, faFolderOpen, faMessage, faUser, faBell } from "@fortawesome/free-regular-svg-icons";
import { faBars, faBinoculars, faCirclePlus, faDollarSign, faLock, faTableColumns, faUsers, faUsersBetweenLines } from "@fortawesome/free-solid-svg-icons";
import HumanResources from "./views/Operations/HumanResources/HumanResources";
import Scheduling from "./views/ISPManagement/Scheduling/Scheduling";
import Login from "./views/Login/Login";
import PasswordReset from "./views/PasswordReset";
import MySchedule from "./views/Account/MySchedule/MySchedule";
import MyDocuments from "./views/Account/MyDocuments/MyDocuments";
import Payroll from "./views/Operations/Payroll/Payroll";
import PayrollPeriod from "./views/Operations/Payroll/PayrollPeriod/PayrollPeriod";
import PayrollEditor from "./views/Operations/Payroll/PayrollEditor/PayrollEditor";
import NotificationSettings from "./views/Account/NotificationSettings/NotificationSettings";
import Cookies from "universal-cookie";
import Shadow from "./views/Admin/Shadow";
import Dashboard from "./views/Dashboard/Dashboard";
import { Redirect } from "react-router-dom";
import Onboarding from "./views/Onboarding";
import Reports from "./views/Operations/Reports/Reports";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import Authenticate from "./views/Authenticate";
import CreateCompany from "./views/Admin/CreateCompany";
import ChangePasswords from "./views/Admin/ChangePasswords";
import ApiManager from "./ApiManager.tsx";
import Debug from "./views/Debug/debug";

export default function getRoutes(auth, permissions) {
    const title = new Cookies().get('userData')?.['title'];

    let routes = [];

    if (auth?.companyUserIdentifier) {
        routes = [
            new WebpageRoute('Profile', Profile, '/profile', faUser, 'Account'),
            new WebpageRoute('Time Clock', TimeClock, '/time-clock', faClock, 'Account'),
            new WebpageRoute('My Schedule', MySchedule, '/my-schedule', faCalendar, 'Account'),
            new WebpageRoute('My Documents', MyDocuments, '/my-documents', faFolderOpen, 'Account'),
            new WebpageRoute('Dashboard', Dashboard, '/dashboard', faTableColumns, 'ISP Management'),
            new WebpageRoute('My Company', MyCompany, '/my-company', faUsers, 'ISP Management', 'myCompany'),
            new WebpageRoute('Payroll Editor', PayrollEditor, '/payroll/edit/:payrollIdentifier', faDollarSign, null, null),
            new WebpageRoute('Payroll Period', PayrollPeriod, '/payroll/:payrollIdentifier', faDollarSign, null, null),
            new WebpageRoute('Payroll', Payroll, '/payroll', faDollarSign, 'Operations', 'payroll'),
            new WebpageRoute('Reports', Reports, '/reports', faChartLine, 'Operations', 'reports'),
            new WebpageRoute('Scheduling', Scheduling, '/scheduling', faBars, 'ISP Management', 'scheduling'),
            new WebpageRoute('Authenticate', Authenticate, '/authenticate'),         
        ];
        if(title === 'AO' || title === 'BC'){
            routes.push(new WebpageRoute('Notification Settings', NotificationSettings, '/notification-settings', faBell, 'Account'));
        }
        if(permissions.includes('hrProfile') || permissions.includes('hrPermissions') || permissions.includes('hrTimesheet') || permissions.includes('hrSchedule') || permissions.includes('hrPay') || permissions.includes('hrDocuments') || permissions.includes('hrBwc')){
            routes.push(new WebpageRoute('Human Resources', HumanResources, '/human-resources', faUsersBetweenLines, 'Operations'))
        }

        if(ApiManager.DEV_MODE){
            routes.push(new WebpageRoute('Debug', Debug, '/debug'))
        }

        routes.push(new WebpageRoute('Redirect', () => {return <Redirect to='/dashboard'/>}, '/'));
    } else if (auth?.adminIdentifier) {
        routes = [
            new WebpageRoute('Payroll Editor', PayrollEditor, '/payroll/edit/:payrollIdentifier', faDollarSign, null, null),
            new WebpageRoute('Payroll Period', PayrollPeriod, '/payroll/:payrollIdentifier', faDollarSign, null, null),
            new WebpageRoute('Human Resources', HumanResources, '/human-resources', faUsersBetweenLines, 'Operations'),
            new WebpageRoute('Shadow User', Shadow, '/shadow', faBinoculars, 'Operations'),
            new WebpageRoute('Change Passwords', ChangePasswords, '/change-passwords', faLock, 'Operations'),
            new WebpageRoute('Create Company', CreateCompany, '/create-company', faCirclePlus, 'Operations'),
            new WebpageRoute('Authenticate', Authenticate, '/authenticate'),
        ];
    } else {
        routes = [
            new WebpageRoute('Password Reset', PasswordReset, '/reset-password/:passwordResetToken'),
            new WebpageRoute('Onboarding', Onboarding, '/onboarding/:onboardingToken'),
            new WebpageRoute('Authenticate', Authenticate, '/authenticate'),
            new WebpageRoute('Login', Login, '/'),
        ];
    }

    return routes.filter(r => {
        return !r.permission || permissions.includes(r.permission);
    });
}

class WebpageRoute {
    name;
    component;
    path;
    icon;
    category;
    permission;

    constructor(name, component, path, icon = null, category = null, permission = null) {
        this.name = name;
        this.component = component;
        this.path = path;
        this.icon = icon;
        this.category = category;
        this.permission = permission;
    }
}