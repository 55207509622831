/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faGripLines, faTrash, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { convertTime, intToTime, stringTimeToInt } from "../../../../tools.js";
import './DraggableComponents.css'
import React, {useEffect} from 'react'
import { useDraggable } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function DraggableStopRow({stop, index, handleSetRouteStop, handleRemoveStop, allStops, conflicts}) {
  const { attributes, listeners, setNodeRef, isDragging, active, setActivatorNodeRef, transform, transition } = useSortable({
    id: stop?.id,
    data: stop,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  ///////////////////
  /// VARIABLES
  ///////////////////

  const earliestTimeForStartTime = (index !== 0 && allStops[index - 1].endTime) 
  ? stringTimeToInt(allStops[index - 1].endTime) + (allStops[index - 1].endTimeIsNextDay ? 1440 : 0) 
  : 0;

  const earliestTimeForEndTime = stop.startTime 
  ? stringTimeToInt(stop.startTime) + (stop.startTimeIsNextDay ? 1440 : 0) 
  : null;

  const conflictSeverity = conflicts.stops[index].reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);

  ///////////////////
  /// CONTENT
  ///////////////////

  const conflictListItems = conflicts.stops[index].map((element, index) => (
    <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
      <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
      <p style={{margin: 0}}>{element.message}</p>
    </div>
  ));

  const popover = (
    <Popover style={{position: 'fixed'}}>
      <Popover.Header></Popover.Header>
      <Popover.Body>
        {conflictListItems?.length > 0 ? conflictListItems : <></>}
      </Popover.Body>
    </Popover>
  );

  return (
    <tr
      ref={setNodeRef}
      style={style}
    > 
      <td style={{verticalAlign: 'middle', textAlign: 'center'}} ref={setActivatorNodeRef} {...attributes}{...listeners}>
           <FontAwesomeIcon icon={faGripLines}/>
      </td>
      <td style={{verticalAlign: 'middle'}}>
          {!!conflictSeverity &&
            <OverlayTrigger placement='top' overlay={popover}>
              <FontAwesomeIcon icon={faTriangleExclamation} style={{color: conflictSeverity === 1 ? 'gold' : conflictSeverity === 2 ? 'orange' : 'red', marginRight: 8}}/>
            </OverlayTrigger>
          }
          <span>{stop.name}</span>
      </td>
      <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
        <CustomRouteTimePicker earliestSelectableTime={earliestTimeForStartTime} value={stop.startTime} currentValueIsNextDay={stop.startTimeIsNextDay} setValue={(time, isNextDay) => {handleSetRouteStop('startTime', time, index); handleSetRouteStop('startTimeIsLocked', true, index); {handleSetRouteStop('startTimeIsNextDay', isNextDay, index)}}}/>
      </td>
      <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
        <Form.Check checked={stop.startTimeIsLocked} onChange={() => handleSetRouteStop('startTimeIsLocked', !stop.startTimeIsLocked, index)}/>
      </td>
      <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
        <CustomRouteTimePicker earliestSelectableTime={earliestTimeForEndTime} value={stop.endTime} currentValueIsNextDay={stop.endTimeIsNextDay} setValue={(time, isNextDay) => {handleSetRouteStop('endTime', time, index); handleSetRouteStop('endTimeIsLocked', true, index); {handleSetRouteStop('endTimeIsNextDay', isNextDay, index)}}} disabled={!stop.startTime}/>
      </td>
      <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
        <Form.Check checked={stop.endTimeIsLocked} onChange={() => handleSetRouteStop('endTimeIsLocked', !stop.endTimeIsLocked, index)}/>
      </td>
      <td style={{verticalAlign: 'middle', textAlign: 'center'}}>
        <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={() => handleRemoveStop(index)} color="red" icon={faTrash}/>
      </td>
    </tr>
  );
}

function CustomRouteTimePicker({ earliestSelectableTime, step = 15, value, currentValueIsNextDay, setValue, disabled }) {
  const arrayTimes = [];
  let count = earliestSelectableTime;

  // Generate times in 15-minute increments up to 48 hours
  for (let i = 0; i < 2880; i += step) {
    if (count >= 2880) break;
    arrayTimes.push({
      time: intToTime(count % 1440),
      isNextDay: count >= 1440,
    });
    count += step;
  }

  // Check if the current value is on the next day
  const isNextDay = Boolean(currentValueIsNextDay);

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant=""
        disabled={disabled}
        bsPrefix=""
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '100%', border: 'none', boxShadow: 'none'}}
      >
        {value ? 
        <div style={{ opacity: disabled ? 0.3 : 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <span>{convertTime(value)}</span>
            {isNextDay && <span style={{ opacity: 0.7, fontSize: 12 }}>Next Day</span>}
        </div>
        :
        <span>Select Time</span>
}     
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ maxHeight: 300, minWidth: 190, overflowY: 'auto', width: '100%' }}>
        {arrayTimes.map(({ time, isNextDay }, index) => (
          <Dropdown.Item key={index} onClick={() => setValue(time, isNextDay)}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 12 }}>
              <span>{convertTime(time)}</span>
              <span style={{ opacity: 0.7, fontSize: 12 }}>{isNextDay ? 'Next Day' : ''}</span>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function DraggableVehicle({vehicle, isMobile, handleSetRoute}){
    const { attributes, listeners, setNodeRef, isDragging, transform } = useDraggable({
        id: `vehicle-${vehicle.uid}`,
        data: vehicle,
    });

    const conflictSeverity = vehicle.conflicts.reduce((accumulator, currentValue) => Math.max(accumulator, currentValue.severity), 0);
    const conflictListItems = vehicle.conflicts.map((element, index) => {
        return (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                <FontAwesomeIcon icon={faTriangleExclamation} style={{color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red'}}/>
                <p style={{margin: 0,}}>{element.message}</p>
            </div>
        )
    });

    const popover = (
        <Popover style={{position:'fixed'}}>
            <Popover.Header></Popover.Header>
            <Popover.Body>
                {conflictListItems?.length > 0 ? conflictListItems : <></>}
            </Popover.Body>
        </Popover>
    )

    const getContent = () => (
        <div className="draggable-item" onClick={isMobile ? () => {handleSetRoute('vehicle', vehicle)} : undefined} style={{fontWeight: 'bold', cursor: 'pointer', padding: 8, display: 'flex', minWidth: 260, justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', borderRadius: 6, fontSize: 14, gap: 8}}>
            <span>{vehicle.name}</span>
            {conflictListItems.length === 0 ? <></> :
                <OverlayTrigger placement='top' overlay={popover}>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{color: conflictSeverity === 1 ? 'gold' : conflictSeverity === 2 ? 'orange' : 'red'}}/>
                </OverlayTrigger>
            }
        </div>
    )
    
    return (
    <div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={{
            opacity: isDragging ? 0.5 : 1,
            backgroundColor: isDragging ? '#f0f0f0' : 'transparent',
        }}
        > 
        {getContent()}
    </div>
    );
}


export function DraggableUser({ user, isMobile, handleSetRoute }) {
    const { attributes, listeners, setNodeRef, isDragging, transform } = useDraggable({
        id: `driver-${user.uid}`, // Unique ID for each draggable
        data: user,
    });

    useDisableTextSelection(isDragging)

    ///////////////////
    /// CONTENT
    ///////////////////
    const getContent = () => (
        <div className="draggable-item" onClick={isMobile ? () => { handleSetRoute('user', user) } : undefined} style={{ fontWeight: 'bold', cursor: 'pointer', padding: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid lightgray', gap: 8, borderRadius: 6, fontSize: 13 }}>
            <div>
                <p style={{ margin: 0 }}>{`${user.firstName} ${user.middleName ? `${user.middleName} ` : ''}${user.lastName}`}</p>
                <p style={{ margin: 0, opacity: .5 }}>{user.title}</p>
            </div>
            {user.conflicts.length > 0 &&
                <OverlayTrigger placement='top' overlay={
                    <Popover style={{ position: 'fixed' }}>
                        <Popover.Header></Popover.Header>
                        <Popover.Body>
                            {user.conflicts.map((element, index) => (
                                <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: element.severity === 0 ? 'lightgreen' : element.severity === 1 ? 'yellow' : element.severity === 2 ? 'orange' : 'red' }} />
                                    <p style={{ margin: 0 }}>{element.message}</p>
                                </div>
                            ))}
                        </Popover.Body>
                    </Popover>
                }>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: user.conflicts.reduce((max, conflict) => Math.max(max, conflict.severity), 0) === 1 ? 'gold' : user.conflicts.reduce((max, conflict) => Math.max(max, conflict.severity), 0) === 2 ? 'orange' : 'red' }} />
                </OverlayTrigger>
            }
        </div>
    );

    return (
        <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={{
                opacity: isDragging ? 0.5 : 1,
                backgroundColor: isDragging ? '#f0f0f0' : 'transparent',
            }}
        > 
        {getContent()}
        </div>
    );
}

export function DraggableTerminal({
    terminal,
    stops,
  }) {
    const { attributes, listeners, setNodeRef, isDragging, transform } = useDraggable({
      id: `terminal-${terminal.uid}`,
      data: terminal,
    });
  
    const totalStopForTerminal = stops.reduce(
      (acc, el) => acc + Number(el.terminalIdentifier === terminal.uid),
      0
    );
  
    return (
      <div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 12px',
            border: '1px solid var(--bs-gray-300)',
            borderRadius: 4,
            backgroundColor: isDragging ? 'var(--bs-primary-light)' : 'var(--bs-white)',
            boxShadow: isDragging ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
            cursor: 'grab',
            transition: 'background-color 0.2s, transform 0.2s',
            transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : 'none',
            opacity: isDragging ? .5 : 1
        }}
    >
        <span style={{
            fontWeight: 'bold',
            color: 'var(--bs-dark)',
        }}>
            {terminal.name}
        </span>
        <span style={{
            fontSize: '0.875rem',
            color: 'var(--bs-primary)'
        }}>
            {totalStopForTerminal} stops
        </span>
      </div>
    );
  }

export function DraggableClient({
    client,
    stops,
  }) {
    const { attributes, listeners, setNodeRef, isDragging, transform } = useSortable({
      id: `client-${client.uid}`,
      data: client,
    });
  
    const totalStopsForClient = stops.reduce(
      (acc, el) => acc + Number(el.clientIdentifier === client.uid),
      0
    );

    return (
      <div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '8px 12px',
            border: '1px solid var(--bs-gray-300)',
            borderRadius: 4,
            backgroundColor: isDragging ? 'var(--bs-primary-light)' : 'var(--bs-white)',
            boxShadow: isDragging ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
            cursor: 'grab',
            transition: 'background-color 0.2s, transform 0.2s',
            transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : 'none',
            opacity: isDragging ? .5 : 1
        }}
    >
        <span style={{
            fontWeight: 'bold',
            color: 'var(--bs-dark)',
        }}>
            {client.name}
        </span>
        <span style={{
            fontSize: '0.875rem',
            color: 'var(--bs-primary)'
        }}>
            {totalStopsForClient} stops
        </span>
      </div>
    );
  }

//////////////////////////
/// CUSTOM HOOKS
//////////////////////////

function useDisableTextSelection(isDragging) {
    useEffect(() => {
        document.body.style.userSelect = isDragging ? 'none' : '';
        return () => {
            document.body.style.userSelect = '';
        };
    }, [isDragging]);
}
