/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useState } from "react"
import { Dropdown, Form, InputGroup} from "react-bootstrap";

export default function ComboBox({ search, setSearch, options, placeholder, onSelect, onClear, filters, style }){
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedValue, setSelectedValue] = useState(search);
  
    const handleSelect = (option) => {
      setSelectedValue(option.label);
      setSearch(option.label);
      setShowDropdown(false);
      if (onSelect) {
        onSelect(option.value);
      }
    };
  
    return (
        <Dropdown show={showDropdown} onToggle={setShowDropdown}>
          <Dropdown.Toggle
              onClick={() => setShowDropdown(!showDropdown)}
              style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  lineHeight: "1.5",
                  border: "1px solid #ced4da",
                  backgroundColor: "#fff",
                  color: "#495057",
                  ...style,
              }}
              aria-expanded={showDropdown}
              >
              {selectedValue || placeholder || "Select or type..."}
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={showDropdown}
            style={{
              width: "100%",
              padding: 8,
              maxHeight: 400,
              overflow: 'visible',
            }}
          >
              {Boolean(filters) ?
                  <InputGroup style={{marginBottom: 8}}>
                      <Form.Control
                          type="text"
                          value={search}
                          placeholder="Search..."
                          onChange={(e) => {setSearch(e.target.value)}}
                          autoFocus
                      />
                      {filters}
                  </InputGroup>
                  : 
                  <Form.Control
                      type="text"
                      value={search}
                      placeholder="Search..."
                      onChange={(e) => {setSearch(e.target.value)}}
                      autoFocus
                  />
              }
              <div style={{overflow: 'auto', maxHeight: 300}}>
                  <Dropdown.Item onClick={() => {onClear(); setSelectedValue(''); setSearch('')}}>
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <span><b> - Clear</b> -</span>
                  </div>
                  </Dropdown.Item>
                  {options.length > 0 ? (
                      options.map((option, index) => (
                      <Dropdown.Item
                          key={index}
                          onClick={() => handleSelect(option)}
                      >
                          {option.content ?? option.label}
                      </Dropdown.Item>
                      ))
                  ) : (
                      <Dropdown.Item
                      disabled
                      style={{textAlign: 'center'}}
                      >
                      No options available
                      </Dropdown.Item>
                  )}
              </div>
          </Dropdown.Menu>
        </Dropdown>
    );
  };