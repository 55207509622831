/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import LoadingWrapper from '../components/LoadingWrapper.js';

export default function Authenticate({ match }) {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('authToken');
    const destination = searchParams.get('destination') ?? '';

    new Cookies().set('lhToken', token, { path: '/' });

    setTimeout(() => {
      window.location.replace(`${window.location.origin}/${destination}`);
    }, 1000);
  }, []);

  return <LoadingWrapper isLoading={true} />;
}
