/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { useEffect, useMemo, useState } from "react"
import { ApiRequest } from "../../ApiManager.tsx";
import { getNameMatches } from "../../tools";
import QuickTable from "../../components/QuickTable";
import LoadingWrapper from "../../components/LoadingWrapper";
import DropdownControl from "../../components/DropdownControl";
import SearchBar from "../../components/SearchBar";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPencil } from "@fortawesome/free-solid-svg-icons";
import AlertConfirmation from "../../components/AlertModals/AlertConfirmation.js";

export default function ChangePasswords({}){
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingResources, setIsLoadingResources] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [companies, setCompanies] = useState([])
    const [users, setUsers] = useState([])
    const [terminals, setTerminals] = useState([])
    const [selectedCompanyIdentifier, setSelectedCompanyIdentifier] = useState()
    const [search, setSearch] = useState('')
    const titles = ['Admin', 'AO', 'BC', 'Driver'];

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if(selectedCompanyIdentifier){
            loadChangePasswordResources()
        }
    }, [selectedCompanyIdentifier])

    function loadData(){
        new ApiRequest('admin', 'getCompanies', setIsLoading, (response) => {
            setCompanies(response.companies);
        }).withNoAlertOnSuccess().send()
    }

    function loadChangePasswordResources(){
        new ApiRequest('admin', 'getPasswordChangeResources', setIsLoadingResources, (response) => {
            setTerminals(response.terminals);
            response.users.forEach((user) => user.password = undefined)
            setUsers(response.users);
        }).withData({ companyIdentifier: selectedCompanyIdentifier }).withNoAlertOnSuccess().send()
    }

    function handleSubmit(){
        const encodedData = {
            users: users.filter((user) => user.isSelected)
        }
        new ApiRequest('admin', 'changePasswords', setIsSubmitting, () => {
            setUsers((prevState) => prevState.map((user) => ({...user, isSelected: false})))
            setShowAlertModal(false)
        }).withData(encodedData).withNoAlertOnSuccess().send()
    }

    function handleUpdateUser(key, value, companyUserIdentifier) {
        setUsers((prevState) => {
            return prevState.map((user) => {
                if (user.companyUserIdentifier === companyUserIdentifier) {
                    return {
                        ...user,
                        [key]: value, 
                    };
                }
                return user;
            });
        });
    }

    function handleTerminalSelection(terminalIdentifier) {
        const allSelected = users
          .filter((user) => user.terminalIdentifier === terminalIdentifier)
          .every((user) => user.isSelected);

        setUsers((prevState) => {
            return prevState.map((user) => {
                if(user.terminalIdentifier === terminalIdentifier){
                    return {
                        ...user,
                        isSelected: !allSelected, 
                    };
                }
                return user;
            })
        })
    }

    function handleTitleSelection(title) {
        const allSelected = users
          .filter((user) => user.title === title)
          .every((user) => user.isSelected);

        setUsers((prevState) => {
            return prevState.map((user) => {
                if(user.title === title){
                    return {
                        ...user,
                        isSelected: !allSelected, 
                    };
                }
                return user;
            })
        })
    }

    const filteredUsers = useMemo(() => {
        const filtered = users.filter((employee) => getNameMatches(employee, search));
        // Terminated Users are sorted to the bottom
        return filtered.sort((a, b) => {
            if (a.title === null && b.title !== null) {
            } else if (a.title !== null && b.title === null) {
                return -1; 
            }
            return 0;
        });
    }, [users, search]);

    ////////////////////////
    /// UI ELEMENTS
    ////////////////////////

    /// Table Rows
    const userRows = filteredUsers.map((user) => {
        return (
            <ChangePasswordUserRow key={user.companyUserIdentifier} user={user} handleUpdateUser={handleUpdateUser}/>
        )
    })
    
    /// Filter Dropdown Elements
    const selectByTitleFilter = (
        <Dropdown autoClose='outside' >
            <Dropdown.Toggle variant='outline-primary'>Select by Title</Dropdown.Toggle>
            <Dropdown.Menu>
                {titles.map((title) => {
                    return <Dropdown.Item key={title} onClick={() => handleTitleSelection(title)}>{title}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )

    const selectTerminalFilter = (
        <Dropdown autoClose='outside' >
            <Dropdown.Toggle variant='outline-primary'>Select by Terminal</Dropdown.Toggle>
            <Dropdown.Menu>
                {terminals.map((terminal) => {
                   return  <Dropdown.Item key={terminal.uid} onClick={() => handleTerminalSelection(terminal.uid)}>{terminal.name}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    )

    const selectedAllFilter = (
        <Dropdown autoClose='outside' >
            <Dropdown.Toggle variant='outline-primary'>Select All</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => setUsers((prevState) => prevState.map((user) => ({...user, isSelected: true})))}>Select All</Dropdown.Item>
                <Dropdown.Item onClick={() => setUsers((prevState) => prevState.map((user) => ({...user, isSelected: false})))}>Unselect All</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )

    const totalSelected = users.reduce((acc, el) => acc + Number(!!el.isSelected), 0);

    return (
        <LoadingWrapper isLoading={isLoading}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 12, flex: 1, padding: 12, overflow: 'auto'}}>
                <DropdownControl title='Select Company' selection={selectedCompanyIdentifier} setSelection={setSelectedCompanyIdentifier} itemNames={companies.map(c=>c.name)} itemValues={companies.map(c=>c.uid)}/>
                <LoadingWrapper isLoading={isLoadingResources}>
                    { !! selectedCompanyIdentifier &&
                        <>
                        <hr style={{margin: 0}}/>
                        { users.length > 0 ? 
                            <Row>
                                <Col lg={12}>
                                    <SearchBar label={`Name Search`} value={search} setValue={setSearch}/>
                                </Col>
                                    <div style={{display: 'flex', justifyContent: 'end', gap: 8, margin: 8, flexWrap: 'wrap'}}>
                                        {selectByTitleFilter}
                                        {selectTerminalFilter}
                                        {selectedAllFilter}
                                    </div>
                                { userRows.length > 0 
                                    ? <QuickTable responsive headers={['Name', 'Title', 'Terminal', 'Password', "Selected"]} rows={userRows}/>
                                    : <h4 style={{textAlign: 'center'}}>No users in this company with the applied filters/search.</h4>
                                }
                                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <span><b>Total Selected: {totalSelected}</b></span>
                                    <Button disabled={totalSelected < 1} onClick={() => setShowAlertModal(true)} style={{color: 'white'}}>Submit Password Changes</Button>
                                </div>
                            </Row>
                        : 
                            <h4 style={{textAlign: 'center'}}>No users in this company.</h4>
                        }
                    </>
                    }
                </LoadingWrapper>

            </div>
            <Modal centered show={showAlertModal} onHide={() => setShowAlertModal(false)}>
                <AlertConfirmation isLoading={isSubmitting} callBack={handleSubmit} title="Change Passwords" message={`Are you sure you want to change passwords for ${totalSelected} employee${totalSelected > 1 ? 's' : ''}?`} buttonLabel='Submit'/>
            </Modal>
        </LoadingWrapper>
    )
}

function ChangePasswordUserRow({user, handleUpdateUser}){
    const name = `${user.firstName} ${user.middleName ? `${user.middleName.substring(0, 1)} ` : ''}${user.lastName}`;
    const isLocked = user.password === undefined;
    const isSelected = user.isSelected

    return (
        <tr key={user.companyUserIdentifier}>
                <td style={{verticalAlign: 'middle'}}>{name}</td>
                <td style={{verticalAlign: 'middle'}}>{user.title}</td>
                <td style={{verticalAlign: 'middle'}}>{user.terminalName}</td>
                <td style={{verticalAlign: 'middle'}}>
                    {isSelected ? (
                        <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
                            <Form.Control
                                disabled={isLocked}
                                value={isLocked ? "RANDOM PASSWORD" : user.password}
                                onChange={(e) => handleUpdateUser('password', e.target.value, user.companyUserIdentifier)}
                            />
                            {isLocked ? (
                                <FontAwesomeIcon 
                                    icon={faPencil} 
                                    onClick={() => handleUpdateUser('password', '', user.companyUserIdentifier)} 
                                />
                            ) : (
                                <FontAwesomeIcon 
                                    icon={faLock} 
                                    onClick={() => handleUpdateUser('password', undefined, user.companyUserIdentifier)} 
                                />
                            )}
                        </div>
                    ) : (
                        <span style={{ opacity: 0.7 }}>Not Selected</span>
                    )}
                </td>
                <td style={{verticalAlign: 'middle'}}>
                    <Form.Check type="switch" checked={Boolean(user.isSelected)} onChange={(e) => handleUpdateUser('isSelected', e.target.checked, user.companyUserIdentifier)}/>
                </td>
            </tr>
    )
}