/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFViewer } from '@react-pdf/renderer';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import OfferLetterPdf from '../views/Operations/HumanResources/HRDocuments/OfferLetterPdf';
import moment from 'moment';

const defaultOfferLetterData = {
    companyName: "ABC, Inc.",
    employeeName: 'Jane Doe',
    employeeType: 0,
    startDate: moment().format('YYYY-MM-DD'),
    supervisorName: "John Doe",
    terminalAddress: {
        thoroughfare: '123 Delivery St.',
        premise: '',
        locality: 'Richfield',
        postalCode: '44286',
        administrativeArea: 'OH'
    },
    startTime: '08:00',
    payType: 'ph',
    payRate: 15,
    expectedAvailability: '1111100',
    dailyStartTime: '08:00',
    benefits: 'Medical, Dental, Vision',
    aoName: `John Doe`,
    aoPhoneNumber: '1234567890',
    aoEmail: 'jdoe@gmail.com'
}

export default function OfferLetterEditor({value, setValue, offerLetterData = defaultOfferLetterData, setSelectedOfferLetter}) {
    const [cursorIndex, setCursorIndex] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [showHelp, setShowHelp] = useState(false);
    const [toggleView, setToggleView] = useState(false);
    const ref = useRef();

    const handleOnChange = (event) => {
        setValue(event.target.value);
        if(setSelectedOfferLetter){
            setSelectedOfferLetter(null);
        }    
    }

    const handleValidateSelectionStart = () => {
        let pointer = ref.current.selectionStart;

        while (pointer >= 0) {
            if (value[pointer] == '[') {
                const newSelectionStart = pointer;
                pointer = ref.current.selectionStart;
                while (pointer < value.length) {
                    if (value[pointer] == ']') {
                        ref.current.selectionStart = newSelectionStart;
                        setCursorIndex(newSelectionStart);
                        ref.current.selectionEnd = pointer + 1;
                        return;
                    } else if (value[pointer] == '[') {
                        break;
                    }
                    pointer++;
                }
                break;
            }
            pointer--;
            if (pointer >= 0 && value[pointer] == ']') {
                break;
            }
        }

    }

    const onMouseUp = (event) => {
        setTimeout(() => {
            setCursorIndex(ref.current?.selectionStart);
            handleValidateSelectionStart();
        })
    }

    const onKeyUp = (event) => {
        setCursorIndex(ref.current?.selectionStart);

        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
            handleValidateSelectionStart();
        }

    }

    const variables = [
        'CompanyName',
        'CreatedDate',
        'EmployeeName',
        'EmployeeType',
        'StartDate',
        'SupervisorName',
        'TerminalAddress',
        'StartTime',
        'Pay',
        'ExpectedAvailability',
        'DailyStartTime',
        'Benefits',
        'AOName',
        'AOPhoneNumber',
        'AOEmail',
    ];

    const variableButtons = variables.map((v) => {
        return (
            <Button variant='outline-primary' key={v} onClick={() => {setValue(`${value.substring(0, cursorIndex)}[${v}]${value.substring(cursorIndex)}`)}}>{v}</Button>
        )
    })

    const variableDropdownItems = variables.map((v) => {
        return (
            <Dropdown.Item key={v} onClick={() => {setValue(`${value.substring(0, cursorIndex)}[${v}]${value.substring(cursorIndex)}`)}}>{v}</Dropdown.Item>
        )
    })

    return (
        <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 6,
              }}
            >
              <Button
                variant="outline-primary"
                onClick={() => {
                  setToggleView(!toggleView);
                }}
              >
                {toggleView ? "View Editor" : "View Preview"}
              </Button>
            </div>
          {toggleView ? (
            <PDFViewer height={1150} width={'100%'}>
                <OfferLetterPdf content={value} offerLetterData={offerLetterData}/>
            </PDFViewer>
          ) : (
            <div style={{ display: "flex", position: "relative", height: "100vh" }}>
              <div
                style={{
                  flex: 1,
                  overflow: "auto",
                  padding: 12,
                  backgroundColor: "gray",
                  position: "relative",
                  fontSize: 14,
                  zoom: zoom,
                }}
              >
                <div
                  style={{
                    height: "auto",
                    width: 950,
                    marginLeft: "auto",
                    marginRight: "auto",
                    backgroundColor: "white",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      height: 1150,
                    }}
                  >
                    <Form.Control
                      ref={ref}
                      onKeyUp={onKeyUp}
                      onMouseUp={onMouseUp}
                      as="textarea"
                      style={{
                        resize: "none",
                        border: "none",
                        borderRadius: 0,
                        fontSize: 14,
                        flex: 1,
                        overflow: "auto",
                        padding: 50,
                      }}
                      value={value}
                      onChange={handleOnChange}
                      maxLength={15000}
                    />
                  </div>
                </div>
              </div>
              <div
                className="desktop-flex"
                style={{
                  flexDirection: "column",
                  gap: 12,
                  padding: 6,
                  overflowY: "auto",
                  flexShrink: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <span>{`Zoom: ${parseInt(zoom * 100)}%`}</span>
                  <Form.Range
                    value={zoom}
                    onChange={(event) => {
                      setZoom(event.target.value);
                    }}
                    min={0.3}
                    max={1.0}
                    step={0.05}
                    style={{
                      backgroundColor: "rgb(220, 230, 250)",
                      padding: 4,
                      borderRadius: 6,
                    }}
                  />
                </div>
                {variableButtons}
                <div style={{ flex: 1 }} />
                <Button
                  onClick={() => {
                    setShowHelp(true);
                  }}
                  style={{
                    background: "none",
                    color: "var(--bs-primary)",
                    border: "none",
                    padding: 0,
                  }}
                >
                  Help <FontAwesomeIcon icon={faQuestionCircle} />
                </Button>
              </div>
    
              <div
                className="mobile-flex"
                style={{ position: "absolute", top: 8, right: 8, gap: 6 }}
              >
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={
                    <Popover>
                      <Popover.Body
                        style={{
                          padding: 6,
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span>{`Zoom: ${parseInt(zoom * 100)}%`}</span>
                        <Form.Range
                          value={zoom}
                          onChange={(event) => {
                            setZoom(event.target.value);
                          }}
                          min={0.3}
                          max={1.0}
                          step={0.05}
                          style={{
                            backgroundColor: "rgb(220, 230, 250)",
                            padding: 4,
                            borderRadius: 6,
                          }}
                        />
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button>
                    <FontAwesomeIcon
                      style={{ color: "white" }}
                      icon={faMagnifyingGlass}
                    />
                  </Button>
                </OverlayTrigger>
                <Dropdown>
                  <Dropdown.Toggle style={{ color: "white" }}>
                    <span style={{ marginRight: 6 }}>Insert</span>
                    <FontAwesomeIcon icon={faPlus} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>{variableDropdownItems}</Dropdown.Menu>
                </Dropdown>
                <Button
                  style={{ color: "white" }}
                  onClick={() => {
                    setShowHelp(true);
                  }}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Button>
              </div>
            </div>
          )}
    
          <Modal
            show={showHelp}
            onHide={() => {
              setShowHelp(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Offer Letter Guide</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>Variables</h4>
              <p>
                Variables allow you to create one offer letter which can be used for
                all of your new hires.
              </p>
              <p>
                You can think of variables as placeholders for information which
                varies between each new hire. When the offer letter is generated for
                the employee, these placeholders will be replaced with the
                appropriate information for that employee.
              </p>
              <p>
                For example, <b>[StartDate]</b> is a Variable for the start date of
                the employee. Wherever you put <b>[StartDate]</b> in your offer
                letter, the actual start date (ex. <b>Jan 1, 2024</b>) will appear.
              </p>
              <p>
                Simply leave your cursor where you would like to insert a variable
                and select the variable from the menu
              </p>
            </Modal.Body>
          </Modal>
        </div>
      );

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 6}}>
                <Button variant='outline-primary' onClick={() => {setToggleView(!toggleView)}}>
                    {toggleView ? 'View Editor' : 'View Preview'}
                </Button>
            </div>
            { toggleView ? 
                <PDFViewer height={1150} width={'100%'}>
                    <OfferLetterPdf content={value} offerLetterData={offerLetterData}/>
                </PDFViewer>
            :
                <div style={{display: 'flex', position: 'relative'}}>
                    <div style={{flex: 1, overflow: 'auto', padding: 12, backgroundColor: 'gray', position: 'relative', fontSize: 14, zoom: zoom}}>
                        <div style={{height: 1100, width: 950, marginLeft: 'auto', marginRight: 'auto', position: 'relative', backgroundColor: 'white'}}>
                            <Form.Control 
                                ref={ref} 
                                onKeyUp={onKeyUp} 
                                onMouseUp={onMouseUp} 
                                as='textarea' 
                                style={{resize: 'none', border: 'none', borderRadius: 0, fontSize: 14, height: 940, width: '100%', overflow: 'hidden', padding: 50}} 
                                value={value} 
                                onChange={handleOnChange} 
                                maxLength={5000}
                            />
                            <span className='unselectable-text' style={{position: 'absolute', left: 0, right: 0, bottom: 140, borderTop: '1px dashed black', opacity: 0.5, textAlign: 'center', fontStyle: 'italic', fontSize: 12}}>
                                NO TEXT BELOW THIS LINE
                            </span>
                            <span className='unselectable-text' style={{position: 'absolute', left: 50, bottom: 120, fontSize: 14}}>
                                Please sign and date to confirm receipt of this document. 
                            </span>
                            <div className='unselectable-text' style={{position: 'absolute', left: 50, right: 50, bottom: 50, display: 'flex', gap: 64}}>
                                <span style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 64}}>Employee's Signature</span>
                                <span style={{borderTop: '1px solid black', paddingLeft: 12, paddingRight: 64}}>Date</span>
                            </div>
                        </div>
                    </div>
                    <div className='desktop-flex' style={{flexDirection: 'column', gap: 12, padding: 6, overflowY: 'auto', flexShrink: 0}}>
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>

                            <span>{`Zoom: ${parseInt(zoom * 100)}%`}</span>
                            <Form.Range value={zoom} onChange={(event) => {setZoom(event.target.value)}} min={0.3} max={1.0} step={0.05} style={{backgroundColor: 'rgb(220, 230, 250)', padding: 4, borderRadius: 6}}/>
                        </div>

                        {variableButtons}

                        <div style={{flex: 1}}/>
                        <Button onClick={() => {setShowHelp(true)}} style={{background: 'none', color: 'var(--bs-primary)', border: 'none', padding: 0}}>Help <FontAwesomeIcon icon={faQuestionCircle}/></Button>
                        
                    </div>



                    <div className='mobile-flex' style={{position: 'absolute', top: 8, right: 8, gap: 6}}>
                        <OverlayTrigger trigger='click' placement='bottom' overlay={
                            <Popover>
                                <Popover.Body style={{padding: 6, textAlign: 'center', display: 'flex', flexDirection: 'column'}}>
                                    <span>{`Zoom: ${parseInt(zoom * 100)}%`}</span>
                                    <Form.Range value={zoom} onChange={(event) => {setZoom(event.target.value)}} min={0.3} max={1.0} step={0.05} style={{backgroundColor: 'rgb(220, 230, 250)', padding: 4, borderRadius: 6}}/>
                                </Popover.Body>
                            </Popover>
                        }>
                            <Button>
                                <FontAwesomeIcon style={{color: 'white'}} icon={faMagnifyingGlass}/>
                            </Button>
                        </OverlayTrigger>
                        <Dropdown>
                            <Dropdown.Toggle style={{color: 'white'}}>
                                <span style={{marginRight: 6}}>Insert</span>
                                <FontAwesomeIcon icon={faPlus}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {variableDropdownItems}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button style={{color: 'white'}} onClick={() => {setShowHelp(true)}}><FontAwesomeIcon icon={faQuestionCircle}/></Button>
                    </div>
                </div>
            }


            <Modal show={showHelp} onHide={() => {setShowHelp(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title>Offer Letter Guide</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Variables</h4>
                    <p>Variables allow you to create one offer letter which can be used for all of your new hires.</p>
                    <p>You can think of variables as placeholders for information which varies between each new hire. When the offer letter is generated for the employee, these placeholders will be replaced with the appropriate information for that employee.</p>
                    <p>For example, <b>[StartDate]</b> is a Variable for the start date of the employee. Wherever you put <b>[StartDate]</b> in your offer letter, the actual start date (ex. <b>Jan 1, 2024</b>) will appear.</p>
                    <p>Simply leave your cursor where you would like to insert a variable and select the variable from the menu</p>
                </Modal.Body>
            </Modal>
        </div>

    )
    

}